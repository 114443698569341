@charset "UTF-8";
/*注册的左半部分 common/register/left.sass */
.register-back {
  background: url("/static/web/image/register_bg.jpg") no-repeat center/cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/register_bg.jpg',sizingMethod='scale');
  height: 960px; }

.register-con {
  padding-top: 90px;
  height: 870px; }

.reg-left {
  float: left;
  width: 600px;
  color: #fff;
  text-align: left; }
  .reg-left .topic-title {
    padding-top: 60px;
    font-size: 100px;
    font-weight: 100; }
  .reg-left .topic-tip {
    display: inline-block;
    padding: 20px 0 80px 0;
    font-size: 26px; }
  .reg-left .effect-list {
    padding: 0;
    margin: 0; }
  .reg-left .effect-item {
    margin-bottom: 24px;
    vertical-align: middle;
    height: 50px;
    font-size: 20px; }
    .reg-left .effect-item .item-con {
      float: left;
      line-height: 50px; }
    .reg-left .effect-item .icon {
      float: left;
      background: url("/static/web/image/sprite_register_icon.png") no-repeat 2px 0px;
      width: 50px;
      height: 50px;
      margin-right: 15px; }
    .reg-left .effect-item .icon.company-icon {
      background-position: -114px 0px; }
    .reg-left .effect-item .icon.user-icon {
      background-position: -59px 0px; }
    .reg-left .effect-item .icon.course-icon {
      background-position: -159px 0px; }
