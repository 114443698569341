@charset "UTF-8";
/*百科的左边列表 common/wiki/list.sass */
h2.ency-tit {
  padding-top: 30px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #04c356;
  border-bottom: 1px solid #f1f1f1; }

.ency-tit .ency-tit-cn {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 2px solid #04c356; }

.ency-item {
  padding: 20px 0;
  height: 98px;
  border-bottom: 1px solid #f1f1f1; }
  .ency-item .item-img {
    display: inline-block;
    width: 134px;
    height: 98px;
    vertical-align: top; }
  .ency-item .item-info {
    display: inline-block;
    padding-left: 30px;
    width: 590px;
    vertical-align: top; }
  .ency-item .item-info-top {
    height: 30px;
    line-height: 30px; }
    .ency-item .item-info-top .item-info-tit {
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      color: #323232; }

.ency-item .item-info-tit .i-link {
  color: #323232; }

.ency-item .item-info-tit .i-link:hover {
  color: #04c356;
  text-decoration: underline; }

.item-info-top .item-info-time {
  float: right;
  font-size: 12px;
  color: #bbb; }

.ency-item .item-info-descri {
  font-size: 14px;
  color: #8e8e8e;
  line-height: 21px;
  height: 42px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all; }

.ency-list-lf .item-hours .hours-icon {
  display: inline-block;
  padding-top: 6px;
  background: url("/static/web/image/sprite_more_information.png") no-repeat -44px 0;
  position: relative;
  top: 10px;
  width: 20px;
  height: 16px; }

.ency-list-lf .item-times .times-icon {
  display: inline-block;
  padding-top: 6px;
  background: url("/static/web/image/sprite_more_information.png") no-repeat -44px 0;
  position: relative;
  top: 10px;
  width: 20px;
  height: 16px; }

.ency-list-lf .item-hours {
  display: inline-block;
  height: 18px;
  color: #bbb;
  font-size: 12px;
  cursor: pointer; }

.ency-list-lf .item-times {
  display: inline-block;
  height: 18px;
  color: #bbb;
  font-size: 12px;
  cursor: pointer; }

.ency-list-lf .item-hours:hover .hours-icon {
  background-position: -1px 0; }

.ency-list-lf .item-times .times-icon {
  background-position: -65px 0; }

.ency-list-lf .item-times:hover .times-icon {
  background-position: -20px 0; }
