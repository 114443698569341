@charset "UTF-8";
/* banner */
.pg-main {
  background: #fff;
  /*** 精品课程 ***/
  /*** 名师大咖 ***/
  /* 消息-资讯-活动 */
  /* 活动 */
  /* 百科 */
  /* 平台优势 */
  /* 客户列表 */ }
  .pg-main .top-banner {
    height: 460px; }
  .pg-main .bx-wrapper .bx-viewport {
    border: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .pg-main .bx-wrapper .bx-pager {
    padding-top: 0;
    z-index: 111; }
  .pg-main .bx-wrapper .bx-pager,
  .pg-main .bx-wrapper .bx-controls-auto {
    top: -30px; }
  .pg-main .bx-wrapper .bx-default-pager {
    position: relative; }
  .pg-main .bx-wrapper .bx-pager.bx-default-pager a {
    background: #fff; }
    .pg-main .bx-wrapper .bx-pager.bx-default-pager a.active {
      background: #666; }
  .pg-main #main_banner {
    height: 400px; }
    .pg-main #main_banner .slide {
      height: 100%;
      display: none; }
  .pg-main .lk-ban {
    width: 100%;
    height: 100%;
    display: inline-block; }
  .pg-main .swp-btn-prev,
  .pg-main .swp-btn-next {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 182px;
    z-index: 100;
    pointer-events: auto;
    border-top: 3px solid rgba(0, 0, 0, 0.15);
    border-left: 3px solid rgba(0, 0, 0, 0.15);
    border-right: transparent;
    border-bottom: transparent; }
  .pg-main .swp-btn-prev {
    left: 10px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .pg-main .swp-btn-next {
    right: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  .pg-main .swp-btns {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 9;
    pointer-events: none;
    margin-left: -590px;
    height: 0; }
  .pg-main .swiper-pagination-bullet-active {
    background: #fff; }
  .pg-main .swp-btns .swiper-pagination-bullet {
    width: 12px;
    height: 12px; }
  .pg-main .top-course {
    padding: 10px 0 40px 0; }
  .pg-main .cnt-lab {
    text-align: center;
    font-size: 22px;
    color: #000; }
  .pg-main .cnt-lab i {
    width: 80px;
    height: 2px;
    display: block;
    background: #ccc;
    margin: 8px auto 0 auto; }
  .pg-main .cnt-opt {
    text-align: right;
    line-height: 40px; }
  .pg-main .lk-view-more {
    color: #8a8a8a;
    font-size: 14px; }
  .pg-main .the-teacher {
    height: 508px;
    overflow: hidden;
    background: none;
    position: relative; }
  .pg-main .the-teacher .cnt-lab {
    color: #fff;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: left; }
  .pg-main .the-teacher .bd-cnt {
    position: relative; }
  .pg-main .bg-blur-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: url("/static/web/image/bg-main-teachers.jpg") center no-repeat;
    background-position: auto 100%; }
  .pg-main .teacher-warpper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%; }
  .pg-main .teacher-wp {
    text-align: left; }
    .pg-main .teacher-wp .teacher-list {
      width: 960px;
      min-height: 100px;
      padding: 0 10px;
      position: relative;
      background: rgba(255, 255, 255, 0.2); }
    .pg-main .teacher-wp .teacher-list:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
      border-top: 50px solid rgba(255, 255, 255, 0.2);
      border-left: 25px solid rgba(255, 255, 255, 0.2);
      border-right: 25px solid transparent;
      border-bottom: 50px solid transparent; }
  .pg-main .teacher-item {
    width: 76px;
    height: 76px;
    display: inline-block;
    margin: 10px 10px 10px 0;
    border: 1px solid transparent; }
    .pg-main .teacher-item.active {
      border: 1px solid #04c356; }
    .pg-main .teacher-item[src=''] {
      display: none; }
  .pg-main .teacher-list .lk-view-more {
    float: right;
    color: #000;
    font-size: 18px;
    line-height: 100px; }
  .pg-main .teacher-info {
    height: 290px;
    display: none;
    background: #343745;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .pg-main .teacher-info.active {
    display: block; }
  .pg-main .teacher-portrait {
    width: 290px;
    height: 290px;
    margin: 0;
    float: left;
    vertical-align: middle; }
  .pg-main .teacher-introduce {
    width: 820px;
    float: right;
    margin-right: 45px;
    display: inline-block; }
  .pg-main .teacher-name {
    margin-top: 70px;
    font-size: 30px;
    color: #fff; }
  .pg-main .teacher-grade {
    width: 74px;
    line-height: 26px;
    text-align: center;
    background: #04c557;
    font-size: 14px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px; }
  .pg-main .teacher-education {
    color: #9ca9c6;
    margin: 24px 0 20px 0; }
  .pg-main .teacher-education .lk-view-more {
    color: #fff;
    float: right;
    font-size: 14px; }
  .pg-main .teacher-detail {
    color: #858b98;
    line-height: 18px;
    font-size: 12px;
    letter-spacing: 1px; }
  .pg-main .message {
    text-align: left;
    padding: 46px 0 70px 0; }
  .pg-main .message .information {
    width: 740px;
    min-height: 380px;
    float: left; }
  .pg-main .activity,
  .pg-main .encyclopedias {
    width: 362px;
    float: right; }
  .pg-main .message .tab {
    text-align: left;
    line-height: 38px;
    border-bottom: 1px solid #eee; }
  .pg-main .message .tab-item {
    color: #04c356;
    font-size: 20px;
    position: relative;
    display: inline-block; }
  .pg-main .message .tab-item:before {
    content: '';
    width: 100%;
    border-bottom: 1px solid #04c356;
    display: inline-block;
    position: absolute;
    bottom: -2px;
    left: 0; }
  .pg-main .message .lk-view-more {
    font-size: 14px;
    color: #8a8a8a;
    float: right; }
  .pg-main .information-item {
    overflow: auto;
    margin-top: 30px; }
  .pg-main .ifm-cover {
    width: 136px;
    height: 98px;
    float: left; }
  .pg-main .ifm-cnt {
    width: 574px;
    float: right; }
  .pg-main .ifm-title {
    color: #323232;
    font-size: 18px; }
  .pg-main .ifm-name {
    color: #323232; }
    .pg-main .ifm-name:hover {
      color: #04c557;
      text-decoration: underline; }
  .pg-main .ifm-desc {
    width: 510px;
    height: 40px;
    color: #8e8e8e;
    font-size: 14px;
    margin: 10px 0 6px 0; }
  .pg-main .ifm-date {
    float: right;
    color: #C7C7C7;
    font-size: 12px; }
  .pg-main .view-hour,
  .pg-main .view-times {
    color: #CACACA;
    font-size: 12px; }
  .pg-main .activity-list {
    width: 100%;
    height: 210px;
    position: relative;
    margin-top: 10px; }
    .pg-main .activity-list .bx-wrapper .bx-viewport {
      left: 0; }
    .pg-main .activity-list .bx-wrapper .bx-default-pager {
      height: 10px;
      overflow: hidden;
      text-align: right; }
    .pg-main .activity-list .bx-wrapper .bx-pager,
    .pg-main .activity-list .bx-wrapper .bx-controls-auto {
      top: -68px; }
    .pg-main .activity-list .bx-wrapper .bx-default-pager a {
      margin: 0 4px; }
    .pg-main .activity-list .bx-wrapper .bx-pager-item:last-child {
      margin-right: 10px; }
    .pg-main .activity-list .bx-wrapper #swiper_activity .slide {
      height: 210px;
      display: none; }
  .pg-main .act-btns {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: inline-block; }
  .pg-main .swp-act-pagination {
    padding: 0 10px;
    line-height: 30px;
    text-align: right;
    position: relative; }
  .pg-main .swp-act-pagination .swiper-pagination-bullet {
    margin: 0 3px; }
  .pg-main .act-title {
    height: 46px;
    line-height: 46px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3); }
  .pg-main .swp-act-prev {
    float: left; }
  .pg-main .swp-act-next {
    float: right; }
  .pg-main .swp-act-prev,
  .pg-main .swp-act-next {
    width: 60px;
    cursor: pointer; }
  .pg-main .encyclopedias {
    margin-top: 50px; }
  .pg-main .subject-type {
    min-width: 52px;
    padding: 0 5px;
    height: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    background: #04c356;
    margin-right: 10px; }
  .pg-main .encyclopedias-list {
    font-size: 0;
    padding: 10px 0; }
  .pg-main .encyclopedias-item {
    width: 100%;
    color: #a1a1a1;
    font-size: 14px;
    margin-top: 10px;
    line-height: 22px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap; }
  .pg-main .platform-adv {
    background: url("/static/web/image/bg_adv.jpg") center no-repeat; }
  .pg-main .platform-adv .bd-cnt {
    height: 510px; }
  .pg-main .adv-list {
    margin-top: 60px; }
  .pg-main .adv-item {
    width: 295px;
    float: left; }
  .pg-main .adv-item .ico {
    height: 150px;
    background: url("/static/web/image/sprite_home_icon.png") no-repeat; }
  .pg-main .adv-1 .ico {
    background-position: 73px 0; }
  .pg-main .adv-2 .ico {
    background-position: -243px 0; }
  .pg-main .adv-3 .ico {
    background-position: -546px 0; }
  .pg-main .adv-4 .ico {
    background-position: -846px 0; }
  .pg-main .adv-tt {
    margin: 30px 0 24px 0;
    color: #a3a5b5;
    font-size: 22px; }
  .pg-main .adv-cnt {
    color: #888D9E;
    font-size: 16px; }
  .pg-main .btn-right-now {
    width: 338px;
    height: 54px;
    outline: none;
    color: #04c356;
    font-size: 22px;
    margin-top: 64px;
    background: none;
    font-weight: bold;
    line-height: 54px;
    display: inline-block;
    border: 1px solid #04c356; }
  .pg-main .customer {
    margin: 60px 0; }
  .pg-main #swiper_customers {
    height: 146px; }
    .pg-main #swiper_customers .slide {
      width: 146px;
      height: 146px; }
  .pg-main .customer .cnt-title {
    margin-bottom: 30px; }
