@charset "UTF-8";
html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p,
blockquote, th, td, nav, span, button {
  margin: 0;
  padding: 0;
  outline: none;
  letter-spacing: 1px;
  font-family: Helvetica, Tahoma, Arial, "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", STXihei, STHeiti, Heiti, SimSun, sans-serif;
  font-size: 100%; }

body {
  background: #f1f1f1; }

a {
  text-decoration: none; }

ul, ol, li {
  list-style: none; }

/* 模块 */
.bd-blc {
  min-width: 1180px;
  text-align: center;
  position: relative; }

.bd-cnt {
  width: 1180px;
  height: 100%;
  display: inline-block; }

/* 顶部导航 */
.header {
  width: 100%;
  height: 80px;
  color: #8a8a8a;
  text-align: center;
  background: #323535;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.nav-item {
  width: 117px;
  font-size: 16px;
  float: left;
  display: inline-block; }

.nav-item:hover {
  background: #434646; }

.nav-item.active {
  background: #4b4e4e; }

.header .bd-cnt {
  line-height: 80px; }

.header .hd-logo,
.header .nav-bar {
  float: left;
  padding: 0; }

.header a {
  color: #8a8a8a;
  display: inline-block;
  margin: 0;
  padding: 0; }

/* 企业logo */
.hd-logo {
  height: 100%; }

.hd-logo img {
  width: 90px;
  height: 60px;
  border: none;
  margin-right: 80px;
  vertical-align: middle; }

.fl-bar {
  float: right; }

/* 搜索条 */
.hd-search-bar {
  margin-right: 10px;
  display: inline-block; }

.hd-search-bar .search-wp {
  width: 150px;
  height: 28px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -moz-border-radius: 15px;
  border-radius: 15px; }
  .hd-search-bar .search-wp .input-search:focus,
  .hd-search-bar .search-wp:hover > .input-search {
    width: 100%;
    opacity: 1;
    padding-left: 10px; }

.hd-search-bar .input-search {
  width: 0;
  overflow: hidden;
  height: 28px;
  line-height: 28px;
  border: none;
  background: none;
  -moz-border-radius: 15px;
  border-radius: 15px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  outline: none;
  color: #8a8a8a;
  font-size: 13px;
  padding-right: 31px;
  background: #434646;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width .3s ease-out,opacity .1s .4s ease-out;
  -o-transition: width .3s ease-out,opacity .1s .4s ease-out;
  -moz-transition: width .3s ease-out,opacity .1s .4s ease-out;
  transition: width .3s ease-out,opacity .1s .4s ease-out; }

.hd-search-bar .btn-input-search {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  background: url("/static/web/image/sprite_icon1.png") -56px center no-repeat; }

/* 更多 */
.nav-more {
  position: relative; }

.nav-more .triangle {
  width: 0;
  height: 0;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  border-top: 5px solid #8a8a8a;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; }

.nav-more-ul {
  width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 11;
  background: #434646; }

.nav-more:hover > .nav-more-ul {
  display: inline-block; }

.nav-more-ul .nav-more-item {
  width: 100%;
  height: 42px;
  background: #434646;
  display: inline-block; }
  .nav-more-ul .nav-more-item:hover {
    background: #323535; }

.nav-more-ul li {
  line-height: 42px; }

/* 登录按钮 */
.login-bar-warp {
  display: inline-block; }

.login-btns {
  display: inline-block;
  vertical-align: middle; }

.btn-lg {
  width: 70px;
  height: 30px;
  margin: 0;
  float: left;
  border: none;
  outline: none;
  color: #8a8a8a;
  line-height: 30px;
  font-size: 14px;
  background: #434646;
  cursor: pointer; }

.btn-lg:hover {
  color: #e2e2e2; }

.btn-lg.btn-register {
  color: #fff;
  background: #04c567; }

/* 登录之后 */
.login-bar {
  display: none; }

.user-info-wp {
  padding: 0 10px;
  position: relative;
  display: none; }

.user-info-img {
  display: inline-block;
  background: url("/static/web/image/default_head_img.png") no-repeat center/cover;
  width: 34px;
  height: 34px;
  cursor: pointer;
  -moz-border-radius: 2px;
  border-radius: 2px;
  vertical-align: middle; }

.user-info-list {
  position: absolute;
  right: 0;
  top: 79px;
  z-index: 5;
  width: 126px;
  display: none;
  padding: 10px 0;
  font-size: 0;
  cursor: pointer;
  background: #4b4e4e;
  -webkit-transition: all 10s ease-in-out;
  -o-transition: all 10s ease-in-out;
  -moz-transition: all 10s ease-in-out;
  transition: all 10s ease-in-out; }

.user-info-list .u-info-item {
  display: block;
  position: relative;
  background: #4b4e4e;
  height: 42px;
  line-height: 42px;
  color: #8a8a8a;
  font-size: 16px; }
  .user-info-list .u-info-item .letter-num {
    background: #f03d14;
    position: absolute;
    left: 7px;
    top: 13px;
    color: #fff;
    font-size: 12px;
    min-width: 10px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    padding: 0 2px;
    -moz-border-radius: 2px;
    border-radius: 2px; }
    .user-info-list .u-info-item .letter-num:empty {
      display: none; }

.user-info-list .u-info-item:hover {
  background: #323235;
  color: #fff; }

/* footer */
.footer {
  height: 300px;
  color: #767978;
  font-size: 12px;
  background: #323535; }

.fl-warp {
  height: 226px;
  overflow: auto;
  border: 1px solid #373737; }

.footer-left,
.footer-right {
  text-align: left;
  margin-top: 54px; }

.footer-left {
  width: 528px;
  float: left; }

.footer-left .footer-nav {
  width: 400px;
  display: inline-block; }

.footer-left .img-logo-footer {
  width: 90px;
  height: 60px;
  vertical-align: top;
  margin-right: 30px; }

.footer-left .ft-nav-bar {
  height: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid #434343; }

.ft-nav-item {
  color: #acacac;
  font-size: 14px;
  margin-right: 40px; }
  .ft-nav-item:nth-of-type(4n) {
    margin-right: 0; }

.supplement {
  font-size: 12px; }

.footer-right {
  width: 404px;
  float: right; }

.footer .tab {
  text-align: left;
  margin-bottom: 20px;
  border-bottom: 1px solid #434343; }

.footer .tab-item {
  height: 40px;
  color: #acacac;
  font-size: 14px;
  cursor: default;
  margin-right: 30px;
  position: relative;
  display: inline-block; }

.footer .active.tab-item:before {
  content: '';
  width: 100%;
  border-bottom: 1px solid #acacac;
  display: inline-block;
  position: absolute;
  bottom: -1px;
  left: 0; }

.f-links {
  font-size: 12px;
  margin-top: 20px; }

.f-link {
  margin: 0 30px 0 0; }

.copyright {
  font-size: 12px;
  line-height: 60px; }

.f-links a,
.copyright a {
  color: #767978; }

.tab-cnt {
  display: none; }

.tab-cnt.active {
  display: block; }

.qr-code-warp {
  margin-top: 20px; }

.icon-wb-qrcode,
.icon-wx-qrcode {
  width: 30px;
  height: 26px;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
  display: inline-block;
  background: url("/static/web/image/sprites_wx_weibo.png") no-repeat; }

.icon-wx-qrcode {
  background-position: -58px -10px; }

.icon-wx-qrcode:hover {
  overflow: inherit;
  background-position: -10px -10px; }

.icon-wb-qrcode {
  background-position: -57px -52px; }

.icon-wb-qrcode:hover {
  overflow: inherit;
  background-position: -10px -52px; }

.qr-code {
  max-width: 120px;
  height: 120px;
  position: absolute;
  top: -120px;
  left: -45px;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in, top 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: opacity 0.4s ease-in, top 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: opacity 0.4s ease-in, top 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 0.4s ease-in, top 0.3s cubic-bezier(0.19, 1, 0.22, 1); }

.icon-wx-qrcode:hover > .qr-code,
.icon-wb-qrcode:hover > .qr-code {
  top: -130px;
  opacity: 1; }

/* 登录 */
#modal-container .ui-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr=#7f000000,endColorstr=#7f000000);
  width: 100%;
  height: 100%; }

#modal-container .login-wrapper {
  position: absolute;
  width: 424px;
  height: 480px;
  left: 50%;
  top: 50%;
  margin-left: -212px;
  margin-top: -240px; }

#modal-container .close-btn {
  background: url("/static/web/image/close_btn_icon.png") no-repeat right top;
  position: absolute;
  top: -51px;
  right: -4px;
  height: 35px;
  width: 35px;
  cursor: pointer; }

.login-wrapper {
  margin-top: 20px;
  background: #fff;
  width: 424px;
  height: 480px; }

.switch-btns {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  height: 65px; }

.switch-btns .btn {
  float: left;
  height: 65px;
  width: 211px;
  line-height: 56px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer; }

.switch-btns .student-btn {
  margin-right: 2px;
  background: url("/static/web/image/sign in_tab1.png") no-repeat left top; }

.switch-btns .student-btn.active {
  border: none;
  outline: none;
  background: url("/static/web/image/sign in_tab1_visited.png") no-repeat left top; }

.switch-btns .tenant-btn {
  background: url("/static/web/image/sign in_tab2.png") no-repeat left top; }

.switch-btns .tenant-btn.active {
  background: url("/static/web/image/sign in_tab2_visited.png") no-repeat left top; }

.login-con {
  display: none;
  text-align: center; }

.login-fail-tip {
  display: none;
  position: absolute;
  top: -11px;
  left: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color: #ff4918; }

.login-con.active {
  display: block; }

.login-title {
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 38px 0 20px 0;
  font-size: 24px;
  width: 324px;
  border-bottom: 1px solid #e5e5e5; }

.user {
  padding: 30px 51px 0 51px;
  position: relative;
  width: 324px;
  height: 42px;
  text-align: center; }

.user-psd {
  padding-bottom: 60px; }

.user .user-text {
  padding: 0 20px;
  background: #eeeeee;
  width: 284px;
  height: 42px;
  line-height: 42px;
  outline: none;
  font-size: 14px;
  color: #8e8e8e;
  border: 1px solid #eee; }

.user.required .user-text {
  background: #fff7f7;
  width: 282px;
  border: 1px solid #ff4918; }

.user.required .required-tip {
  display: block; }

.user.focus .user-text {
  background: #fff;
  width: 282px;
  border: 1px solid #04c557; }

.user .forget-psd {
  display: inline-block;
  position: absolute;
  right: 31px;
  top: 75px;
  width: 120px;
  height: 36px;
  line-height: 36px;
  color: #8e8e8e;
  font-size: 14px;
  text-decoration: none; }

.user .required-tip {
  display: none;
  position: absolute;
  color: #ff4918;
  font-size: 14px;
  line-height: 30px; }

.user .forget-psd:hover {
  text-decoration: underline; }

.login-footer {
  text-align: left; }

.login-footer .login-btn {
  display: block;
  margin: 0 0 10px 51px;
  background: #04c557;
  color: #fff;
  font-size: 20px;
  width: 324px;
  height: 42px;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer; }

.auto-login-wp {
  display: inline-block;
  margin-left: 51px;
  color: #8e8e8e;
  font-size: 14px; }

.auto-left {
  cursor: pointer; }

.auto-login {
  display: inline-block; }

.login-check-wp {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
  width: 13px;
  height: 13px;
  border: 1px solid #bbb;
  cursor: pointer; }
  .login-check-wp .auto-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 13px;
    opacity: 0;
    z-index: 3; }
  .login-check-wp .auto-select {
    display: none;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 13px; }
  .login-check-wp .auto-select:after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 7px;
    height: 4px;
    border-left: 2px solid #04c557;
    border-bottom: 2px solid #04c557;
    -webkit-transform: rotate(-50deg);
    -moz-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    -o-transform: rotate(-50deg);
    transform: rotate(-50deg); }

.to-register {
  float: right;
  margin-right: 51px;
  color: #ff4918;
  font-size: 14px; }

.ke-button-common {
  background: none; }
