@charset "UTF-8";
/* 课程Item */
.course-item {
  float: left;
  text-align: left;
  position: relative;
  display: inline-block;
  margin: 20px 20px 20px 0;
  background: #fff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-shadow: 0 1px 9px #f3f0f0;
  box-shadow: 0 1px 9px #f3f0f0;
  -webkit-transition: box-shadow 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: box-shadow 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: box-shadow 0.8s cubic-bezier(0.19, 1, 0.22, 1), -moz-box-shadow 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: box-shadow 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: box-shadow 0.8s cubic-bezier(0.19, 1, 0.22, 1), -moz-box-shadow 0.8s cubic-bezier(0.19, 1, 0.22, 1); }

.course-item:hover {
  -moz-box-shadow: 0 3px 5px #eee;
  box-shadow: 0 3px 5px #eee; }

.course-item:hover > .course-cover-bg {
  opacity: 1; }

.course-item:hover > .course-cover-bg > .icon-play {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.icon-play {
  opacity: 0;
  -webkit-transform: translate3d(0, 32px, 0);
  -moz-transform: translate3d(0, 32px, 0);
  transform: translate3d(0, 32px, 0);
  -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }

.course-item:nth-of-type(4n) {
  margin-right: 0; }

.course-cover-bg,
.course-cover {
  width: 280px;
  height: 166px; }

.course-cover-bg {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.6s cubic-bezier(0, 0.66, 0.18, 0.95);
  -o-transition: all 0.6s cubic-bezier(0, 0.66, 0.18, 0.95);
  -moz-transition: all 0.6s cubic-bezier(0, 0.66, 0.18, 0.95);
  transition: all 0.6s cubic-bezier(0, 0.66, 0.18, 0.95); }

.icon-play {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url("/static/web/image/icon_play.png") center no-repeat; }

.course-desc {
  color: #666;
  padding: 0 10px; }

.course-title {
  width: 260px;
  color: #000;
  padding: 17px 0;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }

.course-info {
  font-size: 12px;
  padding-bottom: 15px;
  position: relative;
  border-bottom: 1px solid #ddd; }

.course-hour {
  margin-right: 3px; }

.course-hour,
.course-times {
  color: #CACACA; }

.clock,
.eyes {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url("/static/web/image/sprite_icon1.png") 0 0 no-repeat; }

.eyes {
  background-position: -28px 0; }

.course-info .course-star {
  float: right; }

.course-bottom {
  min-height: 51px;
  line-height: 51px; }

.course-bottom .course-teacher {
  display: inline-block;
  color: #000;
  font-size: 12px; }

.course-bottom .portrait {
  width: 20px;
  height: 20px;
  margin: -3px 5px 0 0;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #eee; }

.course-bottom .course-price {
  float: right;
  color: #ff4918;
  font-size: 16px; }

/* 五角星 */
.star-rating {
  position: absolute;
  right: 0;
  top: 0;
  width: 112px;
  height: 18px;
  font-size: 0; }

.star-rating-top {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 22px;
  white-space: nowrap; }

.star-five {
  margin: 0 2px 0 0;
  position: relative;
  display: inline-block;
  color: #08c55a;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 7px solid #08c55a;
  -webkit-transform-origin: 1px 8px;
  -moz-transform-origin: 1px 8px;
  -ms-transform-origin: 1px 8px;
  -o-transform-origin: 1px 8px;
  transform-origin: 1px 8px;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.8191520442889916, M12=-0.5735764363510465, M21=0.5735764363510465, M22=0.8191520442889916, SizingMethod='auto expand')"; }

.star-five:before {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 8px solid #08c55a;
  position: absolute;
  height: 0;
  width: 0;
  top: -5px;
  left: -6px;
  display: block;
  content: '';
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  transform: rotate(-35deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.8191520442889918, M12=0.573576436351046, M21=-0.573576436351046, M22=0.8191520442889918, SizingMethod='auto expand')"; }

.star-five:after {
  position: absolute;
  display: block;
  color: #08c55a;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 7px solid #08c55a;
  -webkit-transform: rotate(-70deg);
  -moz-transform: rotate(-70deg);
  -ms-transform: rotate(-70deg);
  -o-transform: rotate(-70deg);
  transform: rotate(-70deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.3420201433256695, M12=0.9396926207859081, M21=-0.9396926207859081, M22=0.3420201433256695, SizingMethod='auto expand')";
  content: ''; }

.star-rating-bottom .star-five {
  color: #dcdcdc;
  border-bottom: 7px solid #dcdcdc; }

.star-rating-bottom .star-five:before {
  border-bottom: 8px solid #dcdcdc; }

.star-rating-bottom .star-five:after {
  color: #f1f1f1;
  border-bottom: 7px solid #dcdcdc; }
