@charset "UTF-8";
/* 充值 */
.pg-member-recharge {
  background: #fff; }
  .pg-member-recharge .recharge-type-name {
    display: inline-block;
    margin-left: 64px;
    font-size: 26px;
    font-weight: normal; }
  .pg-member-recharge .recharge-number-wp {
    display: inline-block;
    margin-left: 176px;
    position: relative;
    top: -5px; }
    .pg-member-recharge .recharge-number-wp .recharge-money {
      font-size: 16px;
      color: #666; }
    .pg-member-recharge .recharge-number-wp .zhibi {
      width: 34px;
      height: 32px;
      border: 1px solid #ddd;
      -moz-border-radius: 4px;
      border-radius: 4px;
      font-size: 20px;
      text-align: center; }
    .pg-member-recharge .recharge-number-wp .recharge-number-tip {
      position: absolute;
      top: 7px;
      right: -70px;
      font-size: 12px;
      font-style: normal;
      color: #aaa; }
    .pg-member-recharge .recharge-number-wp strong.recharge-tips {
      display: inline-block;
      position: absolute;
      top: 38px;
      left: 0;
      color: #fe9c29;
      font-size: 12px;
      font-weight: 400;
      width: 280px; }
  .pg-member-recharge .recharge-package-give {
    display: inline-block;
    margin-left: 224px;
    position: relative;
    top: -5px; }
    .pg-member-recharge .recharge-package-give .recharge-discount {
      font-size: 16px;
      color: #666; }
    .pg-member-recharge .recharge-package-give .recharge-give-number {
      font-size: 20px;
      color: #333; }
  .pg-member-recharge .recharge-sum {
    float: right;
    margin-right: 48px; }
    .pg-member-recharge .recharge-sum .recharge-sum-tip {
      font-size: 18px;
      color: #fe9712; }
    .pg-member-recharge .recharge-sum .package-sum-number {
      font-size: 30px;
      color: #fe9712; }
  .pg-member-recharge .selected {
    border: none;
    background: none; }
