@charset "UTF-8";
/* 下半部分 布局 common/wiki/layout.sass */
.ency-info-list {
  margin: 0 0 20px 0; }

.ency-list-lf {
  float: left;
  margin-right: 20px;
  position: relative;
  background: #fff;
  text-align: left;
  vertical-align: top;
  padding: 0 30px 30px 30px;
  width: 790px; }

.ency-list-rh {
  float: left;
  display: inline-block;
  background: #fff;
  text-align: left;
  vertical-align: top;
  padding: 0 15px;
  width: 280px; }
