.pg-member-buy {
  background: #fff; }
  .pg-member-buy #package-type {
    height: 150px;
    width: 100%; }
  .pg-member-buy .package-type-con h2.package-type-name {
    display: inline-block;
    margin-left: 64px;
    font-size: 26px;
    font-weight: normal;
    line-height: 30px; }
  .pg-member-buy .package-give {
    display: inline-block;
    margin-left: 300px;
    position: relative;
    top: -5px; }
    .pg-member-buy .package-give .give-tip {
      font-size: 16px;
      color: #666; }
    .pg-member-buy .package-give .package-give-number {
      font-size: 20px;
      color: #333; }
  .pg-member-buy .package-sum {
    float: right;
    margin-right: 48px; }
    .pg-member-buy .package-sum .sum-tip {
      font-size: 18px;
      color: #fe9712; }
    .pg-member-buy .package-sum .package-sum-number {
      font-size: 30px;
      color: #fe9712; }
  .pg-member-buy .selected {
    border: none;
    background: none; }
