@charset "UTF-8";
.pg-wiki-index {
  background: #F1F1F1;
  /* 搜索样式 */
  /* 选择标签 */
  /* 百科列表 背景 */ }
  .pg-wiki-index .ency-cnt .search-con {
    margin: 40px auto;
    width: 582px;
    height: 44px; }
    .pg-wiki-index .ency-cnt .search-con .search-text {
      padding: 0 15px;
      float: left;
      font-size: 14px;
      color: #bbb;
      width: 440px;
      height: 44px;
      border: 1px solid #fff;
      line-height: 44px; }
      .pg-wiki-index .ency-cnt .search-con .search-text:focus {
        border: 1px solid #04c567; }
    .pg-wiki-index .ency-cnt .search-con .search-btn {
      float: left;
      position: relative;
      background: #04c557;
      border: none;
      width: 110px;
      height: 46px;
      line-height: 44px;
      text-align: center; }
  .pg-wiki-index .ency-cnt .search-btn .search-icon {
    display: inline-block;
    background: url("/static/web/image/sprite_more_encyclopedias.png") no-repeat -64px 0;
    position: absolute;
    top: 10px;
    left: 40px;
    width: 30px;
    height: 24px;
    cursor: pointer; }
  .pg-wiki-index .label-list {
    margin-bottom: 20px; }
    .pg-wiki-index .label-list h2.lb-title {
      height: 60px;
      line-height: 60px;
      text-align: left;
      font-size: 20px;
      font-weight: normal;
      color: #323232; }
  .pg-wiki-index .lb-list-lf,
  .pg-wiki-index .lb-list-rh {
    display: inline-block;
    padding: 0 40px;
    background: #fff;
    min-height: 276px;
    vertical-align: top; }
  .pg-wiki-index .lb-list-rh .selected-lb-list {
    height: 216px;
    overflow-y: auto; }
  .pg-wiki-index .lb-list-lf {
    width: 759px; }
  .pg-wiki-index .lb-list-rh {
    width: 255px;
    text-align: left; }
  .pg-wiki-index .all-lb-list {
    text-align: left;
    overflow-y: hidden;
    height: 147px;
    -webkit-transition: height .3s ease-in;
    -o-transition: height .3s ease-in;
    -moz-transition: height .3s ease-in;
    transition: height .3s ease-in; }
    .pg-wiki-index .all-lb-list .more {
      overflow: auto;
      height: auto; }
    .pg-wiki-index .all-lb-list .lb-item {
      display: inline-block;
      margin: 0 30px 13px 0;
      padding: 0 5px;
      min-width: 60px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      color: #8e8e8e;
      cursor: pointer; }
    .pg-wiki-index .all-lb-list .select-item {
      background: #04c557;
      color: #fff; }
    .pg-wiki-index .all-lb-list .lb-item:not(.select-item):hover {
      color: #04c557; }
  .pg-wiki-index .all-lb-list.more {
    overflow: auto;
    height: auto;
    -webkit-transition: height .3s ease-in;
    -o-transition: height .3s ease-in;
    -moz-transition: height .3s ease-in;
    transition: height .3s ease-in; }
  .pg-wiki-index .lb-right-footer {
    margin-top: 10px;
    padding: 20px 0;
    border-top: 1px solid #eee;
    text-align: center;
    height: 20px; }
    .pg-wiki-index .lb-right-footer .load-more {
      background: url("/static/web/image/sprite_more_encyclopedias.png") no-repeat 0 0;
      border: none;
      outline: none;
      width: 25px;
      height: 23px;
      cursor: pointer;
      -webkit-transition: -webkit-transform .3s ease-in;
      transition: -webkit-transform .3s ease-in;
      -o-transition: -o-transform .3s ease-in;
      -moz-transition: transform .3s ease-in, -moz-transform .3s ease-in;
      transition: transform .3s ease-in;
      transition: transform .3s ease-in, -webkit-transform .3s ease-in, -moz-transform .3s ease-in, -o-transform .3s ease-in; }
    .pg-wiki-index .lb-right-footer .load-more.active {
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: -webkit-transform .3s ease-in;
      transition: -webkit-transform .3s ease-in;
      -o-transition: -o-transform .3s ease-in;
      -moz-transition: transform .3s ease-in, -moz-transform .3s ease-in;
      transition: transform .3s ease-in;
      transition: transform .3s ease-in, -webkit-transform .3s ease-in, -moz-transform .3s ease-in, -o-transform .3s ease-in; }
  .pg-wiki-index .selected-lb-list .sel-item {
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 10px 35px 10px 5px;
    position: relative;
    min-width: 50px;
    min-height: 16px;
    line-height: 20px;
    border: 1px solid #04c356;
    color: #04c356;
    font-size: 14px;
    text-align: center;
    vertical-align: top; }
  .pg-wiki-index .selected-lb-list .del-btn {
    display: inline-block;
    background: url("/static/web/image/sprite_more_encyclopedias.png") no-repeat -33px 5px;
    position: absolute;
    top: 1px;
    right: 2px;
    width: 20px;
    height: 30px;
    vertical-align: top;
    cursor: pointer; }
  .pg-wiki-index .ency-info-list.ency-list-back {
    background: #fff; }
  .pg-wiki-index .infor-list-wp {
    display: none; }
  .pg-wiki-index .infor-list-wp.active {
    display: block; }
  .pg-wiki-index .label-num {
    display: inline-block;
    padding-bottom: 10px;
    color: #323232;
    font-size: 14px; }
