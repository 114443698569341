@charset "UTF-8";
/* 分页 */
.pagination {
  margin: 30px 0;
  color: #323232;
  font-size: 18px;
  text-align: center; }
  .pagination .pg-warp {
    display: inline-block; }
  .pagination .pg-it {
    float: left;
    min-width: 40px;
    line-height: 40px;
    margin-right: 10px;
    display: inline-block;
    border: 1px solid transparent;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .pagination .pg-it.disabled {
    color: #bbb; }
    .pagination .pg-it.disabled .pg-a {
      color: #bbb;
      cursor: not-allowed; }
  .pagination .pg-it:not(.disabled):hover {
    border: 1px solid #d4d4d4; }
  .pagination .pg-no.active {
    color: #fff;
    border: 1px solid #04c557;
    background: #04c557; }
    .pagination .pg-no.active .pg-a {
      color: #fff; }
  .pagination .pg-a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    color: #323232;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

/* 分页 (异步) */
.pagination-asyn {
  color: #666;
  margin: 15px 0;
  padding: 25px 0;
  /* 跳页 */ }
  .pagination-asyn .pg-left {
    float: left; }
  .pagination-asyn .pg-right {
    float: right; }
  .pagination-asyn .pg-right ul {
    margin: 0 10px;
    padding: 0;
    display: inline-block; }
  .pagination-asyn .pg-right ul li {
    margin-right: 6px;
    display: inline-block; }
  .pagination-asyn .pg-right ul li:last-of-type {
    margin-right: 0; }
  .pagination-asyn .pg-right button {
    min-width: 25px;
    height: 25px;
    color: #666;
    border: none;
    outline: none;
    cursor: pointer;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #f7f8fa; }
  .pagination-asyn .pg-right button:disabled {
    color: #ccc;
    cursor: not-allowed; }
  .pagination-asyn .pg-right button:not(.active):not(:disabled):hover {
    background: #eee; }
  .pagination-asyn .total-result-size {
    color: #333;
    margin: 0 5px;
    font-size: 16px;
    font-weight: bold; }
  .pagination-asyn .pg-right .btn-pg {
    outline: none;
    min-width: 25px;
    height: 25px;
    display: inline-block;
    line-height: normal; }
  .pagination-asyn .btn-pg.active {
    background: #3dce3d;
    color: #fff;
    border: none; }
  .pagination-asyn .pg-right .btn-pg {
    padding: 0 6px; }
  .pagination-asyn .pg-right button.pg-prev {
    padding: 0 10px 0 0; }
  .pagination-asyn .pg-right button.pg-next {
    padding: 0 0 0 10px; }
  .pagination-asyn .jump-page {
    margin-left: 20px;
    line-height: 25px;
    display: inline-block; }
  .pagination-asyn .input-page-num {
    width: 32px;
    height: 23px;
    outline: none;
    margin: 0 4px;
    padding: 2px 6px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ddd; }
  .pagination-asyn .input-page-num:focus {
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #3dce3d; }
  .pagination-asyn .pagination-warp .pg-right button.btn-go-page {
    color: #fff;
    height: 23px;
    background: #ccc;
    margin-left: 8px;
    -moz-border-radius: 4px;
    border-radius: 4px; }
  .pagination-asyn .pagination-warp .pg-right button.btn-go-page:not(.active):not(:disabled):hover {
    background: #bbb; }
