.pg-download-error .bd-cnt {
  background: #fff; }

.pg-download-error .error-tips-warp {
  padding-top: 310px;
  text-align: center;
  border-top: 50px solid #f1f1f1;
  background: #fff url("/static/web/image/download_error.png") center 150px no-repeat; }
  .pg-download-error .error-tips-warp h2 {
    font-weight: normal;
    font-size: 20px; }
