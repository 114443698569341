@charset "UTF-8";
.pg-course-center {
  background: #f1f1f1;
  /*顶部搜索条*/
  /*筛选条件*/
  /* 课程列表 */ }
  .pg-course-center .course-list {
    overflow: auto; }
  .pg-course-center .course-search .search-wp {
    height: 46px;
    margin: 50px 0;
    font-size: 0;
    display: inline-block;
    background: #04c557; }
  .pg-course-center .course-search .input-search {
    height: 100%;
    width: 472px;
    font-size: 14px;
    background: #fff;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 20px;
    float: left;
    border: 1px solid #fff; }
  .pg-course-center .course-search .input-search:focus {
    border: 1px solid #04c557; }
  .pg-course-center .course-search .btn-search {
    width: 110px;
    height: 100%;
    border: none;
    float: right;
    outline: none;
    background: url("/static/web/image/course_search_icon.png") center no-repeat; }
  .pg-course-center .search-filter {
    font-size: 14px;
    background: #fff; }
  .pg-course-center .search-filter .bd-cnt {
    padding: 15px 0; }
  .pg-course-center .ft-row {
    overflow: auto;
    text-align: left;
    padding: 15px 0;
    border-bottom: 1px solid #e5e5e5; }
  .pg-course-center .ft-row:last-child {
    border: none; }
  .pg-course-center .ft-lab {
    float: left;
    width: 100px;
    line-height: 38px;
    display: inline-block;
    color: #323232; }
  .pg-course-center .ft-list {
    float: left;
    display: inline-block;
    width: 1080px; }
  .pg-course-center .ft-item {
    color: #666;
    margin-right: 15px;
    padding: 9px 15px;
    display: inline-block; }
  .pg-course-center .ft-item.active {
    color: #fff;
    background: #04c557; }
  .pg-course-center .search-utils-bar {
    overflow: auto;
    color: #8e8e8e;
    background: #fff;
    line-height: 50px;
    margin-top: 20px;
    padding: 0 20px; }
  .pg-course-center .search-res-stat {
    float: left;
    font-size: 18px; }
  .pg-course-center .res-num {
    color: #ff4918; }
  .pg-course-center .order-rules {
    float: right;
    font-size: 14px; }
  .pg-course-center .order-rule {
    width: 60px;
    color: #323232;
    display: inline-block;
    margin-left: 20px; }
  .pg-course-center .order-rule.active {
    color: #04c567;
    border-bottom: 1px solid #04c567; }
  .pg-course-center .top-course {
    padding: 68px 0 40px 0; }
  .pg-course-center .cnt-lab {
    text-align: center;
    font-size: 22px;
    color: #000;
    /*letter-spacing: 12px*/ }
  .pg-course-center .cnt-lab i {
    width: 80px;
    height: 2px;
    display: block;
    background: #ccc;
    margin: 8px auto 0 auto; }
  .pg-course-center .cnt-opt {
    text-align: right;
    line-height: 40px; }
  .pg-course-center .lk-view-more {
    color: #8a8a8a;
    font-size: 14px; }
  .pg-course-center .not-find {
    margin: 20px 0;
    line-height: 300px;
    background: #fff; }
