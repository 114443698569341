@charset "UTF-8";
/* 课程评价 commom/course/evalute-item.sass */
.course-evalute-item {
  padding: 30px 40px 30px 15px;
  border-bottom: 1px solid #e5e5e5; }

.evalute-item-head-pic {
  display: inline-block;
  margin-right: 10px;
  background: url("/static/web/image/course_detail_test.jpg") center/cover no-repeat;
  width: 52px;
  height: 52px;
  -moz-border-radius: 50%;
  border-radius: 50%;
  vertical-align: top; }

.evalute-item-con {
  display: inline-block;
  width: 627px;
  vertical-align: top; }
  .evalute-item-con .evalute-item-descript {
    line-height: 20px;
    color: #8e8e8e;
    font-size: 14px; }

.evalute-item-con-top {
  margin-bottom: 10px;
  height: 16px;
  line-height: 16px; }
  .evalute-item-con-top .name {
    font-size: 16px;
    color: #323232;
    vertical-align: top; }
  .evalute-item-con-top .grade-wp {
    display: inline-block;
    padding: 0 30px;
    vertical-align: top; }
    .evalute-item-con-top .grade-wp .grade-icon {
      display: inline-block;
      background: url("/static/web/image/grade_icon.png") -2px 0 no-repeat;
      width: 14px;
      height: 14px; }
    .evalute-item-con-top .grade-wp .grade-icon-gray {
      display: inline-block;
      background: url("/static/web/image/grade_icon.png") -14px 0 no-repeat;
      width: 14px;
      height: 14px; }
  .evalute-item-con-top .date {
    font-size: 14px;
    color: #bbb;
    vertical-align: top; }
