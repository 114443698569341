@charset "UTF-8";
.pg-register {
  /*注册*/ }
  .pg-register .reg-right {
    float: right;
    background: #fff;
    width: 500px;
    height: 790px; }
    .pg-register .reg-right .reg-title {
      padding: 40px 0;
      font-size: 24px;
      font-weight: normal;
      color: #04c557;
      text-align: center; }
    .pg-register .reg-right .info-list {
      padding: 0 42px; }
    .pg-register .reg-right .info-item {
      position: relative;
      margin-bottom: 20px;
      height: 42px; }
      .pg-register .reg-right .info-item .required {
        display: none;
        background: #fff7f7;
        position: absolute;
        right: 20px;
        top: 1px;
        font-size: 14px;
        color: #ff4918;
        min-width: 75px;
        height: 40px;
        line-height: 40px; }
      .pg-register .reg-right .info-item .input-text {
        padding: 0 100px 0 20px;
        background: #eee;
        width: 292px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #eee;
        font-size: 14px; }
    .pg-register .reg-right .info-item.required-item:after {
      display: block;
      width: 20px;
      content: "*";
      position: absolute;
      top: 17px;
      left: 0;
      color: #ff4918; }
  .pg-register .info-item.error .input-text {
    border: 1px solid #ff4918;
    background: #fff7f7; }
  .pg-register .info-item.error .required {
    display: block; }
  .pg-register .info-item.focus .required {
    display: none; }
  .pg-register .info-item.focus .input-text {
    border: 1px solid #04c557;
    background: #fff; }
  .pg-register .reg-right .code-wrapper .contact-code {
    width: 176px; }
  .pg-register .reg-right .code-wrapper .send-btn {
    margin-left: 10px;
    background: #04c557;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border: none;
    outline: none;
    width: 100px;
    height: 42px;
    cursor: pointer; }
  .pg-register .reg-right .code-wrapper .send-btn:disabled {
    background: #c8c8c8;
    cursor: not-allowed; }
  .pg-register .reg-right .code-wrapper .required {
    right: 133px; }
  .pg-register .reg-btn {
    margin-top: 10px;
    background: #04c557;
    width: 416px;
    height: 42px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer; }
  .pg-register .reg-footer {
    margin-top: 20px;
    text-align: center; }
    .pg-register .reg-footer .login-con {
      display: inline-block;
      padding-right: 20px;
      font-size: 14px;
      color: #8e8e8e;
      height: 14px;
      line-height: 14px;
      border-right: 1px solid #8e8e8e; }
      .pg-register .reg-footer .login-con .login {
        color: #04c557; }
    .pg-register .reg-footer .back-main {
      display: inline-block;
      padding-left: 20px;
      font-size: 14px;
      cursor: pointer;
      color: #8e8e8e; }
