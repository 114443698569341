@charset "UTF-8";
/*百科的公共右边 common/wiki/right.sass */
.item-main-descri .i-link {
  color: #323232; }

.item-main-descri .i-link:hover {
  color: #04c356;
  text-decoration: underline; }

.ency-list-rh .item-hours .hours-icon {
  display: inline-block;
  padding-top: 6px;
  background: url("/static/web/image/sprite_more_information.png") no-repeat -44px 0;
  position: relative;
  top: 10px;
  width: 15px;
  height: 16px; }

.ency-list-rh .item-times {
  margin-left: 10px; }
  .ency-list-rh .item-times .times-icon {
    display: inline-block;
    padding-top: 6px;
    background: url("/static/web/image/sprite_more_information.png") no-repeat -44px 0;
    position: relative;
    top: 10px;
    width: 18px;
    height: 16px; }

.ency-list-rh .item-hours {
  display: inline-block;
  height: 18px;
  color: #bbb;
  font-size: 12px;
  cursor: pointer; }

.ency-list-rh .item-times {
  display: inline-block;
  height: 18px;
  color: #bbb;
  font-size: 12px;
  cursor: pointer; }

.ency-list-rh .item-hours:hover .hours-icon {
  background-position: -1px 0; }

.ency-list-rh .item-times .times-icon {
  background-position: -65px 0; }

.ency-list-rh .item-times:hover .times-icon {
  background-position: -20px 0; }

.ency-top-tit {
  padding: 20px 0 40px 0;
  height: 42px;
  font-size: 0; }
  .ency-top-tit .ency-msg {
    display: inline-block;
    background: #eee;
    height: 42px;
    width: 139px;
    line-height: 42px;
    text-align: center;
    color: #323232;
    font-size: 16px;
    vertical-align: top;
    cursor: pointer; }
  .ency-top-tit .ency-msg.active {
    background: #04c356;
    color: #fff; }

.ency-top-list {
  height: auto;
  overflow: inherit; }

.ency-top-item {
  height: 120px; }

.top-item-main {
  height: 56px; }
  .top-item-main .item-main-img {
    display: inline-block;
    margin-right: 15px;
    width: 74px;
    height: 56px;
    vertical-align: top; }
  .top-item-main .item-main-descri {
    display: inline-block;
    vertical-align: top; }

.item-main-descri .descri-tit {
  margin-bottom: 9px;
  height: 18px;
  width: 180px;
  font-size: 14px;
  font-weight: normal;
  color: #323232; }

.top-item-descri {
  margin: 6px 0 14px 0;
  height: 44px;
  line-height: 22px;
  font-size: 12px;
  color: #8e8e8e;
  word-break: break-all; }

/* 右边中间列表的样式 */
.ency-md-tit {
  padding: 30px 0 10px 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #f1f1f1; }
  .ency-md-tit .md-more {
    float: right;
    line-height: 24px;
    font-size: 14px;
    color: #8e8e8e;
    cursor: pointer; }
  .ency-md-tit .md-more:hover {
    color: #04c356;
    text-decoration: underline; }

.ency-list-rh .md-tit-name {
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  color: #323232; }

.ency-md-list {
  margin-bottom: 15px;
  max-height: 346px;
  overflow: hidden; }

.ency-md-item {
  margin: 10px 0;
  height: 24px; }
  .ency-md-item .item-num {
    float: left;
    margin-right: 10px;
    background: #999;
    color: #fff;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: top; }
  .ency-md-item .item-link {
    float: left;
    max-width: 240px;
    height: 24px;
    line-height: 24px;
    color: #8e8e8e;
    font-size: 14px; }
  .ency-md-item .item-link:hover {
    color: #04c356;
    text-decoration: underline; }

.ency-md-item.first .item-num {
  background: #e72721; }

.ency-md-item.second .item-num {
  background: #ff8547; }

.ency-md-item.third .item-num {
  background: #ffac38; }

/* 右边底部样式 */
.rh-ft-btm .hot-search {
  display: inline-block;
  margin: 0 12px 20px 8px;
  font-size: 14px;
  color: #323232; }
