@charset "UTF-8";
.pg-information-index {
  background: #F1F1F1;
  /* 资讯列表 */ }
  .pg-information-index .information-banner {
    margin-bottom: 20px;
    background: #8e8e8e;
    height: 250px;
    background: url("/static/web/image/infor_banner.png") center/cover no-repeat;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/infor_banner.png',sizingMethod='scale'); }
  .pg-information-index .infor-list-wp {
    display: none; }
  .pg-information-index .infor-list-wp.active {
    display: block; }
