.pg-wiki-detail {
  background: #F1F1F1; }
  .pg-wiki-detail .infor-list-wp {
    display: none; }
  .pg-wiki-detail .infor-list-wp.active {
    display: block; }
  .pg-wiki-detail .wiki-wp-tit {
    margin: 40px 0 20px 20px;
    font-size: 16px;
    font-weight: normal;
    color: #323232; }
  .pg-wiki-detail .wiki-item {
    padding-left: 20px;
    margin-left: 20px;
    font-size: 14px;
    color: #8e8e8e;
    position: relative; }
    .pg-wiki-detail .wiki-item .rela-link {
      color: #8e8e8e; }
  .pg-wiki-detail .wiki-item:after {
    display: block;
    position: absolute;
    left: 0;
    top: 6px;
    background: #aaa;
    content: '';
    width: 8px;
    height: 8px; }
  .pg-wiki-detail .wiki-item .rela-link:hover {
    cursor: pointer;
    color: #04c356;
    text-decoration: underline; }
