@charset "UTF-8";
.pg-activity-index {
  background: #F1F1F1;
  /* 活动列表 */ }
  .pg-activity-index .information-banner {
    margin-bottom: 20px;
    background: #8e8e8e;
    height: 250px;
    background: url("/static/web/image/activity_banner.jpg") center/cover no-repeat;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/activity_banner.jpg',sizingMethod='scale'); }
  .pg-activity-index .activity-list {
    overflow: hidden; }
  .pg-activity-index .active-item {
    float: left;
    margin-right: 36px;
    position: relative;
    width: 362px;
    height: 210px;
    cursor: pointer;
    margin-bottom: 28px; }
    .pg-activity-index .active-item .active-item-tit {
      padding: 0 10px;
      background: rgba(0, 0, 0, 0.5);
      filter: progid:DXImageTransform.Microsoft.Gradient( startColorstr=#7f000000 ,endColorstr=#7f000000 );
      position: absolute;
      bottom: 0;
      left: 0;
      height: 46px;
      line-height: 46px;
      width: 342px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; }
  .pg-activity-index .active-item:nth-child(2n) {
    margin: 0; }
  .pg-activity-index .ac-it-link {
    display: block;
    width: 100%;
    height: 100%; }
