@charset "UTF-8";
.pg-member-success {
  /* 支付成功 */
  /* 下半部分 */ }
  .pg-member-success .package-type-success {
    background: #fff url("/static/web/image/icon_success.png") no-repeat 450px 14px;
    text-align: center; }
    .pg-member-success .package-type-success .type-success-tip {
      display: inline-block;
      margin-left: 64px;
      position: relative;
      top: -10px;
      font-size: 26px;
      font-weight: normal; }
    .pg-member-success .package-type-success .type-price {
      margin: 3px 0 0 262px;
      color: #fe9712;
      font-size: 20px;
      font-weight: normal;
      text-align: center; }
  .pg-member-success #pay-success {
    margin: 0 auto 278px auto;
    padding-left: 455px;
    width: 745px;
    font-size: 16px;
    text-align: left; }
  .pg-member-success .pay-success-type {
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
    color: #222; }
  .pg-member-success .pay-success-time {
    margin: 15px 0;
    font-size: 16px;
    color: #999;
    font-weight: normal; }
  .pg-member-success .pay-success-else {
    height: 50px;
    line-height: 50px; }
    .pg-member-success .pay-success-else .check-order {
      display: inline-block;
      padding-left: 45px;
      margin: 0 20px;
      background: #fe9712 url("/static/web/image/ico_order.png") no-repeat 15px 15px;
      width: 100px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      -moz-border-radius: 4px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 18px; }
    .pg-member-success .pay-success-else .back-index {
      color: #3dce3d;
      cursor: pointer; }
    .pg-member-success .pay-success-else .back-index:hover {
      text-decoration: underline; }
