.pg-course-detail {
  background: #f1f1f1; }
  .pg-course-detail .course-detail {
    position: relative;
    overflow: hidden;
    height: 504px;
    width: 100%; }
  .pg-course-detail .course-detail-back {
    position: absolute;
    top: 0;
    left: 0;
    height: 504px;
    width: 100%;
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=40);
    filter: blur(50px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=50, MakeShadow=false); }
  .pg-course-detail .course-detail-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .pg-course-detail .detail-top-wp {
    margin-top: 40px;
    padding: 20px;
    background: #fff;
    width: 1140px;
    height: 384px;
    text-align: left; }
  .pg-course-detail .course-img {
    display: inline-block;
    margin-right: 30px;
    width: 650px;
    height: 384px;
    vertical-align: top; }
  .pg-course-detail .course-img .play-btn {
    margin: 165px 0 0 295px;
    background: url("/static/web/image/course_detail_search_icon.png") -135px 1px no-repeat;
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer; }
    .pg-course-detail .course-img .play-btn:disabled {
      background: url("/static/web/image/icon_lockplay.png") 2px 3px no-repeat;
      cursor: not-allowed; }
  .pg-course-detail .course-infor {
    display: inline-block;
    width: 450px; }
  .pg-course-detail h3.course-infor-name {
    padding: 20px 0;
    font-size: 22px;
    font-weight: normal;
    color: #323232; }
  .pg-course-detail .course-infor-relates {
    padding: 20px 0;
    border: 1px solid #e5e5e5;
    border-left: none;
    border-right: none;
    color: #323232;
    font-size: 14px; }
  .pg-course-detail .course-infor-price {
    padding-top: 30px;
    color: #f0ad4e;
    font-size: 30px;
    font-weight: normal; }
  .pg-course-detail .course-infor-btns {
    margin-top: 40px; }
    .pg-course-detail .course-infor-btns button {
      margin-right: 20px;
      height: 48px;
      width: 160px;
      line-height: 48px;
      text-align: center;
      border: none;
      color: #fff;
      font-size: 16px;
      cursor: pointer; }
    .pg-course-detail .course-infor-btns .add-matrix {
      background: #00B83F; }
    .pg-course-detail .course-infor-btns .add-matrix:hover {
      background: #02aa36; }
    .pg-course-detail .course-infor-btns .start-study,
    .pg-course-detail .course-infor-btns .add-plan {
      background: #0DB3D9; }
    .pg-course-detail .course-infor-btns .add-plan a {
      color: #fff;
      display: inline-block;
      width: 100%;
      height: 100%; }
    .pg-course-detail .course-infor-btns .start-study:hover:not(:disabled),
    .pg-course-detail .course-infor-btns .add-plan:hover {
      background: #008be3; }
    .pg-course-detail .course-infor-btns .start-study:disabled {
      cursor: not-allowed;
      background: #0DB3D9 url("/static/web/image/icon_lock26px.png") 22px 9px no-repeat; }
  .pg-course-detail .course-detail-wp {
    text-align: left; }
    .pg-course-detail .course-detail-wp .ency-list-lf {
      text-align: left;
      padding: 30px;
      min-height: 480px;
      margin-bottom: 20px; }
  .pg-course-detail .course-module {
    display: none; }
  .pg-course-detail .star-wp {
    position: relative;
    height: 30px;
    width: 125px; }
  .pg-course-detail .course-module-switch {
    height: 52px; }
    .pg-course-detail .course-module-switch .course-detail-btn {
      display: inline-block;
      background: #f1f1f1;
      position: relative;
      width: 146px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      font-size: 16px;
      color: #323232;
      cursor: pointer; }
    .pg-course-detail .course-module-switch .course-detail-btn.active {
      background: #fff;
      color: #04c557; }
    .pg-course-detail .course-module-switch .course-detail-btn.active:after {
      display: inline-block;
      background: #04c557;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 6px;
      content: ''; }
  .pg-course-detail .chapter-item .chapter-item-name {
    padding: 30px 0 14px 15px;
    font-size: 16px;
    font-weight: normal;
    color: #323232; }
  .pg-course-detail .chapter-item-section .section-item {
    padding: 0 20px;
    margin-bottom: 6px;
    background: #f7f7f7;
    position: relative;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #8e8e8e; }
    .pg-course-detail .chapter-item-section .section-item .section-item-order {
      display: inline-block;
      margin-right: 30px;
      min-width: 10px; }
    .pg-course-detail .chapter-item-section .section-item .section-item-hours {
      display: inline-block;
      float: right;
      width: 90px;
      text-align: center; }
    .pg-course-detail .chapter-item-section .section-item .section-item-btn {
      display: none;
      background: #04c557;
      position: absolute;
      top: 4px;
      right: 20px;
      width: 90px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #fff;
      border: none;
      cursor: pointer; }
    .pg-course-detail .chapter-item-section .section-item .section-item-btn:disabled {
      padding-left: 10px;
      background: #04c557 url("/static/web/image/icon_lock14px.png") 6px 9px no-repeat;
      cursor: not-allowed; }
  .pg-course-detail .chapter-item-section .section-item:hover {
    background: #e5fdf0;
    cursor: pointer; }
    .pg-course-detail .chapter-item-section .section-item:hover .section-item-btn {
      display: block; }
    .pg-course-detail .chapter-item-section .section-item:hover .section-item-hours {
      display: none; }
  .pg-course-detail .course-instro-relate {
    margin-top: 30px; }
  .pg-course-detail .instro-relate-part {
    margin-bottom: 40px; }
    .pg-course-detail .instro-relate-part .instro-part-tit {
      padding: 0 0 10px 10px;
      margin-bottom: 20px;
      position: relative;
      height: 16px;
      line-height: 16px;
      font-size: 16px;
      font-weight: normal;
      color: #323232;
      border-bottom: 1px solid #f1f1f1; }
    .pg-course-detail .instro-relate-part .instro-part-tit:after {
      display: inline-block;
      background: #04c557;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 3px;
      height: 16px; }
    .pg-course-detail .instro-relate-part .instro-part-descript {
      padding: 0 20px 0 10px;
      line-height: 18px;
      font-size: 14px;
      color: #8e8e8e; }
  .pg-course-detail .indutries-list {
    padding-left: 10px; }
    .pg-course-detail .indutries-list .industries-item {
      font-size: 14px;
      color: #8e8e8e;
      display: inline-block;
      white-space: nowrap;
      margin: 0 40px 10px 0; }
  .pg-course-detail .course-label-list {
    padding-left: 10px; }
    .pg-course-detail .course-label-list .course-label-item {
      margin-right: 20px;
      font-size: 14px; }
  .pg-course-detail .course-download-item {
    padding: 30px 40px 30px 0;
    position: relative;
    border-bottom: 1px solid #eee;
    height: 32px; }
    .pg-course-detail .course-download-item .download-item-name {
      display: inline-block;
      padding: 0 120px 0 52px;
      background: url("/static/web/image/course_search_icon1.png") left top no-repeat;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      color: #323232; }
    .pg-course-detail .course-download-item .download-item-btn {
      background: #04c557;
      position: absolute;
      right: 40px;
      top: 30px;
      width: 110px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      border: none;
      cursor: pointer; }
  .pg-course-detail .course-detail-rh .ency-top-list {
    overflow: auto;
    height: auto; }
  .pg-course-detail .teacher-relate {
    margin-bottom: 10px; }
  .pg-course-detail .teacher-wp .teacher-descript {
    padding: 0 15px 0 8px;
    margin-top: 10px;
    font-size: 14px;
    color: #8e8e8e;
    line-height: 22px;
    max-height: 66px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .pg-course-detail .teacher-wp .ency-md-tit {
    padding: 15px 0 10px 10px; }
  .pg-course-detail .organization-relate {
    margin-bottom: 10px; }
  .pg-course-detail .teacher-relate-infor,
  .pg-course-detail .organization-relate-infor {
    padding-bottom: 10px;
    display: inline-block;
    height: 52px;
    line-height: 52px; }
    .pg-course-detail .teacher-relate-infor .teacher-relate-infor-head,
    .pg-course-detail .teacher-relate-infor .organization-relate-infor-head,
    .pg-course-detail .organization-relate-infor .teacher-relate-infor-head,
    .pg-course-detail .organization-relate-infor .organization-relate-infor-head {
      display: inline-block;
      width: 52px;
      height: 52px;
      -moz-border-radius: 50%;
      border-radius: 50%;
      vertical-align: top; }
    .pg-course-detail .teacher-relate-infor .organization-relate-infor-head,
    .pg-course-detail .organization-relate-infor .organization-relate-infor-head {
      background: url("/static/web/image/institute_default.png") center/cover no-repeat;
      filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/institute_default.png',sizingMethod='scale'); }
    .pg-course-detail .teacher-relate-infor .teacher-relate-infor-head,
    .pg-course-detail .teacher-relate-infor .teacher-relate-infor-name,
    .pg-course-detail .organization-relate-infor .teacher-relate-infor-head,
    .pg-course-detail .organization-relate-infor .teacher-relate-infor-name {
      cursor: pointer; }
    .pg-course-detail .teacher-relate-infor .teacher-relate-infor-name,
    .pg-course-detail .teacher-relate-infor .organization-relate-infor-name,
    .pg-course-detail .organization-relate-infor .teacher-relate-infor-name,
    .pg-course-detail .organization-relate-infor .organization-relate-infor-name {
      display: inline-block;
      margin: 0 10px;
      font-size: 18px;
      color: #323232;
      vertical-align: top; }
    .pg-course-detail .teacher-relate-infor .teacher-relate-infor-profess,
    .pg-course-detail .organization-relate-infor .teacher-relate-infor-profess {
      display: inline-block;
      margin-top: 15px;
      background: #04c557;
      width: 80px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      vertical-align: top; }
    .pg-course-detail .teacher-relate-infor .organization-relate-infor-profess,
    .pg-course-detail .organization-relate-infor .organization-relate-infor-profess {
      font-size: 14px;
      color: #8e8e8e; }
  .pg-course-detail .study-person-list {
    padding: 0 0 30px 11px; }
  .pg-course-detail .study-person-item {
    display: inline-block;
    margin-right: 11px;
    width: 52px;
    height: 52px;
    -moz-border-radius: 50%;
    border-radius: 50%; }
  .pg-course-detail .course-course-star {
    display: inline-block;
    margin-bottom: 10px;
    width: 126px; }
  .pg-course-detail .star-five {
    margin: 0 2px 0 2px; }
  .pg-course-detail .course-analog-item {
    margin: 10px 0; }
  .pg-course-detail .icon-analog-paper {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
    background: url("/static/web/image/icon_paper_file.png") center no-repeat; }
  .pg-course-detail .btn-lk-analog {
    color: #666;
    line-height: 20px; }

#course-martrix {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100; }
  #course-martrix .bg-mask {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.course-martrix-con {
  width: 50%;
  max-height: 96%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  overflow-y: auto;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -mos-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.course-martrix-con-middle,
.course-martrix-con-bottom {
  overflow: hidden;
  font-size: 14px;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex; }

.course-martrix-con-middle-left,
.course-martrix-con-bottom-left {
  color: #666;
  width: 120px;
  height: 38px;
  line-height: 38px; }

ul.position-cd-list,
.hserole-cb-list {
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #333; }

.position-cb-list li span.cb-casing,
.hserole-cb-list li span.cb-casing {
  background: #f3f4f5;
  border: none;
  color: #333; }

.course-martrix-con .btns {
  border-top: 1px solid #eee;
  padding-top: 20px;
  text-align: center; }
  .course-martrix-con .btns button {
    width: 95px;
    height: 36px;
    border: none;
    background: #3dce3d;
    color: #fff;
    line-height: 36px;
    text-align: center;
    outline: none;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0 auto;
    display: inline-block;
    cursor: pointer;
    margin-right: 30px;
    font-size: 14px; }
  .course-martrix-con .btns button.cancel-btn {
    border: 1px solid #eee;
    height: 36px;
    background: none;
    color: #666; }

.course-martrix-con li {
  min-width: 90px;
  text-align: center;
  line-height: 36px;
  color: #222;
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
  padding: 0 10px; }

.course-martrix-con li input {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer; }

.course-martrix-con li span {
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 10px; }

.course-martrix-con li span.active {
  height: 36px;
  border-color: #3dce3d;
  background: #3dce3d;
  color: #fff; }

.course-no-data {
  font-size: 20px;
  color: #8e8e8e;
  margin: 100px auto;
  text-align: center; }

.study-no-data {
  padding: 40px 0;
  text-align: center;
  font-size: 16px;
  color: #8e8e8e; }
