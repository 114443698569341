@charset "UTF-8";
/** 当前任务 **/
.clearfix {
  clear: both; }

.user-center {
  padding: 40px 0 20px 0; }
  .user-center .left-col,
  .user-center .right-col {
    height: 1710px;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .user-center .down-arrow {
    position: absolute;
    top: 15px;
    right: 30px;
    width: 7px;
    height: 7px;
    display: inline-block;
    border-left: 1px solid  #8e8e8e;
    border-bottom: 1px solid  #8e8e8e;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 2px 6px;
    -moz-transform-origin: 2px 6px;
    -ms-transform-origin: 2px 6px;
    -o-transform-origin: 2px 6px;
    transform-origin: 2px 6px;
    -webkit-transition: -webkit-transform .3s ease-in;
    transition: -webkit-transform .3s ease-in;
    -o-transition: -o-transform .3s ease-in;
    -moz-transition: transform .3s ease-in, -moz-transform .3s ease-in;
    transition: transform .3s ease-in;
    transition: transform .3s ease-in, -webkit-transform .3s ease-in, -moz-transform .3s ease-in, -o-transform .3s ease-in; }
  .user-center .open .down-arrow {
    -webkit-transform: rotate(-225deg);
    -moz-transform: rotate(-225deg);
    -ms-transform: rotate(-225deg);
    -o-transform: rotate(-225deg);
    transform: rotate(-225deg); }
  .user-center .left-col {
    width: 204px;
    padding-bottom: 20px;
    background: #323235;
    float: left; }
    .user-center .left-col .profile {
      padding: 40px 0 64px 0;
      text-align: center; }
    .user-center .left-col .profile-img {
      width: 134px;
      height: 134px;
      -moz-border-radius: 50%;
      border-radius: 50%; }
      .user-center .left-col .profile-img[src=""] {
        opacity: 0; }
    .user-center .left-col .profile-name {
      font-size: 22px;
      color: #8e8e8e;
      margin-top: 15px; }
    .user-center .left-col .sidebar-menu {
      color: #8e8e8e;
      text-align: left;
      line-height: 44px;
      font-size: 16px; }
    .user-center .left-col .sd-menu {
      height: 44px;
      overflow: hidden; }
    .user-center .left-col .sd-menu.open {
      height: auto; }
      .user-center .left-col .sd-menu.open .down-arrow {
        -webkit-transform: rotate(-225deg);
        -moz-transform: rotate(-225deg);
        -ms-transform: rotate(-225deg);
        -o-transform: rotate(-225deg);
        transform: rotate(-225deg); }
    .user-center .left-col .m-tt {
      position: relative;
      padding: 0 30px; }
    .user-center .left-col .sd-submenu-warp {
      overflow: auto; }
    .user-center .left-col .sd-submenu {
      border-top: 1px solid #3b3f3f; }
    .user-center .left-col .sd-menu-a {
      display: block;
      color: #8e8e8e;
      padding-left: 30px; }
      .user-center .left-col .sd-menu-a:hover {
        background: #4b4d4f; }
    .user-center .left-col .sd-submenu .sd-menu-a {
      padding-left: 74px; }
    .user-center .left-col .sd-menu {
      border-top: 1px solid #3b3f3f; }
    .user-center .left-col .sd-menu,
    .user-center .left-col .sd-submenu {
      width: 100%;
      float: left;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all .2s ease-out;
      -o-transition: all .2s ease-out;
      -moz-transition: all .2s ease-out;
      transition: all .2s ease-out; }
    .user-center .left-col .sd-menu.active,
    .user-center .left-col .sd-submenu.active > .sd-menu-a,
    .user-center .left-col .sd-menu-a.active {
      color: #fff;
      background: #04c557; }
    .user-center .left-col .unread-total {
      color: #fff;
      min-width: 11px;
      text-align: center;
      line-height: normal;
      display: inline-block;
      padding: 0 2px;
      font-size: 12px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      background: #FD4B16;
      margin-left: 3px;
      vertical-align: text-top; }
  .user-center .right-col {
    width: 960px;
    float: right;
    padding: 20px 30px;
    background: #fff; }
    .user-center .right-col .page-tab {
      overflow: auto;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 10px; }
    .user-center .right-col .page-tab-item {
      position: relative;
      float: left;
      font-size: 18px;
      color: #323232;
      cursor: pointer;
      padding: 10px 15px;
      border-bottom: 1px solid transparent; }
      .user-center .right-col .page-tab-item.active {
        border-bottom: 1px solid #04c557; }
      .user-center .right-col .page-tab-item:hover:not(.active) {
        background: #F8F8F8; }
    .user-center .right-col .course-item {
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #e7e7e7; }
      .user-center .right-col .course-item:nth-of-type(3n) {
        margin-right: 0; }
      .user-center .right-col .course-item:nth-of-type(4n) {
        margin-right: 20px; }
  .user-center .mod-item {
    height: 60px;
    overflow: hidden;
    text-align: left;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #f1f1f1; }
    .user-center .mod-item:first-child {
      border-top: 1px solid #f1f1f1; }
    .user-center .mod-item.open {
      height: auto; }
      .user-center .mod-item.open .mod-tt {
        background: #f1f1f1; }
    .user-center .mod-item .mod-tt {
      position: relative;
      color: #323232;
      font-size: 16px;
      padding: 0 24px;
      line-height: 60px;
      overflow: auto; }
      .user-center .mod-item .mod-tt:hover {
        background: #f1f1f1; }
    .user-center .mod-item .down-arrow {
      top: 24px;
      right: 50px; }
    .user-center .mod-item .mod-name {
      width: 440px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 30px; }

.task-list {
  overflow: auto; }

.curr-task .course-bottom {
  line-height: normal;
  position: relative; }

.curr-task .completed {
  font-size: 16px;
  line-height: 16px;
  color: #323232;
  margin: 10px 0; }

.curr-task .progress {
  line-height: 16px;
  width: 204px;
  height: 5px;
  overflow: auto;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #eee; }

.curr-task .progress-bar {
  height: 100%;
  float: left;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  background: #04c557; }

.curr-task .btn-del-task {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("/static/web/image/sprite_Personal Center.png") -21px center no-repeat;
  border: none; }
  .curr-task .btn-del-task:hover {
    background-position: -53px center; }

.exam-task {
  text-align: left; }
  .exam-task .pass-date {
    color: #323232;
    font-size: 14px; }
  .exam-task .exam-item {
    position: relative;
    border: 1px solid #dcdcdc;
    padding: 20px 30px;
    margin-top: 20px; }
    .exam-task .exam-item img {
      max-width: 100%;
      max-height: 100px; }
  .exam-task .exam-name {
    width: 670px;
    font-size: 16px;
    line-height: 24px;
    color: #323232; }
  .exam-task .exam-remark,
  .exam-task .exam-time {
    line-height: 14px;
    font-size: 14px;
    color: #8e8e8e; }
  .exam-task .exam-time {
    margin-top: 12px; }
  .exam-task .exam-date {
    margin-right: 40px; }
  .exam-task .exam-remark {
    width: 500px;
    margin-top: 20px;
    line-height: 22px; }
  .exam-task .btn-go-exam {
    position: absolute;
    right: 30px;
    bottom: 24px;
    width: 134px;
    height: 42px;
    color: #fff;
    border: none;
    font-size: 16px;
    background: #03c456; }
    .exam-task .btn-go-exam.disabled {
      color: #323232;
      background: #f5f5f5;
      border: 1px solid #dcdcdc; }
  .exam-task .exam-score {
    color: #ff4918; }
  .exam-task .ri-bar {
    float: right;
    color: #8e8e8e;
    padding: 10px 15px; }
  .exam-task .btn-more-err {
    color: #8e8e8e; }
    .exam-task .btn-more-err a:hover {
      -webkit-text-decoration: dashed;
      -moz-text-decoration: dashed;
      text-decoration: dashed; }
  .exam-task .err-total {
    margin-right: 30px; }

.icon-err-qst,
.icon-err-all {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  display: inline-block;
  vertical-align: text-top;
  background: url("/static/web/image/sprite_Personal Center.png") no-repeat; }

.icon-err-qst {
  background-position: 2px 0; }

.icon-err-all {
  background-position: -87px 0; }

.message-list .tab-list {
  padding-top: 20px; }

.message-list .unread .mod-tt:before {
  content: '';
  position: absolute;
  top: 26px;
  left: 10px;
  width: 6px;
  height: 6px;
  display: inline-block;
  background: #FD4B16;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.message-list .mod-name,
.message-list .msg-date {
  float: left; }

.message-list .msg-date {
  width: 180px;
  font-size: 14px;
  color: #8e8e8e; }

.message-list .msg-cnt {
  padding: 20px 20px 60px 20px;
  font-size: 14px;
  color: #8e8e8e; }

.message-list .mod-desc {
  border: 1px solid #f1f1f1; }

.message-list .msg-btns {
  text-align: right; }

.message-list .btn-msg-del {
  font-size: 14px;
  color: #323232;
  margin: 10px 15px 15px 0;
  display: inline-block;
  width: 30px;
  height: 30px; }

.btn-msg-del {
  display: inline-block;
  background: url("/static/web/image/trash-grey.png") center no-repeat;
  border: none; }
  .btn-msg-del:hover {
    background: url("/static/web/image/sprite_Personal Center.png") -52px center/auto 20px no-repeat; }

.account-setting .tab-list {
  padding: 0;
  margin-top: -10px; }

.account-setting .right-col .page-tab {
  margin-bottom: 0; }

.account-setting .mod-item:first-child {
  border-top: 0; }

.account-setting .tb-pwd,
.account-setting .tb-info {
  width: 100%;
  color: #323232;
  font-size: 14px;
  padding-top: 30px;
  vertical-align: middle; }

.account-setting .tb-info {
  padding-bottom: 40px; }

.account-setting .td-field-lab {
  width: 116px;
  padding-left: 20px;
  text-align: left; }

.account-setting .td-field-input {
  padding: 10px 0; }

.account-setting .field-input {
  width: 494px;
  height: 46px;
  padding: 0 15px;
  border: 1px solid #dcdcdc; }

.account-setting .mod-desc {
  overflow: auto; }

.account-setting .btn-sbm-pwd {
  height: 46px;
  min-width: 144px;
  font-size: 16px;
  color: #fff;
  border: none;
  background: #04c557; }

.account-setting .curr-pic,
.account-setting .upload-pic {
  float: left;
  margin: 20px 22px 40px 0; }

.account-setting .curr-pic .curr-img,
.account-setting .upload-pic {
  width: 200px;
  height: 200px; }

.account-setting .curr-img-desc {
  font-size: 16px;
  color: #8e8e8e;
  text-align: center;
  margin-top: 5px; }

.account-setting .upload-pic {
  position: relative;
  background: url("/static/web/image/bg-upload.png") center no-repeat, #f1f1f1;
  border: 1px solid #dcdcdc; }

.account-setting .btn-uplaod-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  opacity: 0; }

.account-setting .ke-form {
  height: 100%; }

.account-setting .ke-upload-file {
  height: 100%;
  width: 100%; }

.result-container,
.paper-container {
  margin: 20px 0 10px 0;
  min-height: -webkit-calc(100vh - 100px);
  min-height: -moz-calc(100vh - 100px);
  min-height: calc(100vh - 100px); }

.collection-question .x-tree-component,
.error-record .x-tree-component {
  width: 100%;
  position: relative; }
  .collection-question .x-tree-component .select-item,
  .error-record .x-tree-component .select-item {
    margin: 2px 20px;
    line-height: 62px; }
    .collection-question .x-tree-component .select-item:before,
    .error-record .x-tree-component .select-item:before {
      position: absolute;
      left: 0;
      content: '';
      display: inline;
      height: 62px;
      width: 100%;
      z-index: 0;
      -webkit-transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      -o-transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      background: #fafafa;
      opacity: 0; }
    .collection-question .x-tree-component .select-item.hv:before,
    .error-record .x-tree-component .select-item.hv:before {
      opacity: 1; }
  .collection-question .x-tree-component .xtree-lab:hover,
  .error-record .x-tree-component .xtree-lab:hover {
    border-bottom: none; }
  .collection-question .x-tree-component .xtree-lab,
  .error-record .x-tree-component .xtree-lab {
    border: none;
    position: relative; }
  .collection-question .x-tree-component > .chb-list > .select-item:before,
  .error-record .x-tree-component > .chb-list > .select-item:before {
    opacity: 1; }

.collection-question .chb-switch,
.error-record .chb-switch {
  vertical-align: middle;
  margin-top: -1px;
  margin-right: 5px; }

.collection-question .btn-err-point,
.error-record .btn-err-point {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  z-index: 3;
  width: 96px;
  height: 38px;
  line-height: 38px;
  color: #cdcdcd;
  font-size: 16px;
  margin: 11px; }

.collection-question .hv,
.error-record .hv {
  color: #323232; }
  .collection-question .hv > .btn-err-point,
  .error-record .hv > .btn-err-point {
    color: #fff;
    background: #03c456; }

.error-question .task-list {
  max-height: 1200px;
  overflow: auto; }

.error-question .pp-lf {
  width: 100%;
  float: none; }

.error-question .exam-btns {
  display: block; }

.error-question .pp-top-btns {
  line-height: 22px;
  font-size: 18px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 15px;
  padding-bottom: 10px; }

.error-question .err-top-tt {
  float: left; }

.error-question .err-top-opt {
  float: right; }

.error-question .qst-name {
  max-width: 980px; }

.error-question .btn-all-qst {
  color: #8e8e8e; }
