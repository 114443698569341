@charset "UTF-8";
/*购买的支付方式 commom/member/buy.sass */
#package-pay-type {
  width: 1180px;
  margin: 0 auto 60px auto; }

.package-pay-type-top {
  padding-left: 20px;
  margin-bottom: 20px;
  position: relative;
  line-height: 22px;
  font-size: 16px;
  font-weight: normal;
  color: #222;
  text-align: left; }

.package-pay-type-top:after {
  display: inline-block;
  background: #3dce3d;
  position: absolute;
  left: 0;
  top: 2px;
  content: '';
  height: 19px;
  width: 4px; }

.package-pay-list {
  text-align: left;
  width: 1180px; }

.package-pay-item {
  display: inline-block;
  margin-right: 58px;
  width: 230px;
  cursor: pointer; }
  .package-pay-item .inputs {
    display: inline-block;
    margin-right: 8px;
    background: #fff;
    position: relative;
    top: -9px;
    border: 1px solid #e2e2e1;
    width: 14px;
    height: 14px;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer; }
    .package-pay-item .inputs span {
      position: absolute;
      top: 4px;
      left: 4px; }
    .package-pay-item .inputs input {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      opacity: 0; }
  .package-pay-item .pay-item-img:hover {
    border: 1px solid #3dce3d;
    cursor: pointer; }

.package-pay-item:nth-child(1) {
  margin-left: 25px; }

.package-pay-item.selected .inputs {
  background: #31bafd; }
  .package-pay-item.selected .inputs span {
    display: inline-block;
    background: #fff;
    width: 6px;
    height: 6px;
    -moz-border-radius: 50%;
    border-radius: 50%; }

.package-pay-item.selected .pay-item-img {
  border: 1px solid #3dce3d; }

#make-pay {
  margin: 0 auto;
  width: 1180px;
  text-align: right; }

.agreement {
  margin: 20px 66px 10px 0;
  text-align: right;
  font-size: 14px; }
  .agreement .auto-thirty-wrapper {
    display: inline-block;
    margin: 0 5px -2px 0;
    width: 13px;
    height: 13px;
    border: 0;
    position: relative;
    -moz-border-radius: 3px;
    border-radius: 3px; }
  .agreement .member-rule {
    color: #008cc8;
    cursor: pointer; }
  .agreement .member-rule:hover {
    text-decoration: underline; }

.auto-thirty-wrapper input {
  margin: 0;
  padding: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.auto-thirty-wrapper .minify {
  display: block;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.auto-thirty-wrapper .auto-thirty {
  display: block;
  background: #3dce3d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.auto-thirty-wrapper .auto-thirty:after {
  display: inline-block;
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 4px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  -o-transform: rotate(-50deg);
  transform: rotate(-50deg); }

.make-pay-btn {
  margin-bottom: 110px;
  background: #fe9712;
  width: 360px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  border: none;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 24px;
  cursor: pointer; }
