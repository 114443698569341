@charset "UTF-8";
/* 上半部分 悬浮 common/member/top.sass */
.buy-bg-banner {
  height: 150px;
  background: #f9fafc url("/static/web/image/bg-green.png") no-repeat;
  width: 100%; }

.package-type-con {
  padding-top: 45px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  top: -60px;
  width: 1180px;
  height: 75px;
  -moz-box-shadow: 0 3px 12px rgba(77, 83, 84, 0.1);
  box-shadow: 0 3px 12px rgba(77, 83, 84, 0.1);
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: left; }
