@charset "UTF-8";
/** 考试结果 */
.exam-result .qst-list img {
  max-width: 100%;
  max-height: 200px; }

.exam-result .exam-res-info {
  color: #333;
  text-align: center;
  padding: 20px 10px; }

.exam-result .exam-state {
  background: #ff4a18;
  padding: 15px 0 10px 0; }
  .exam-result .exam-state.pass {
    background: #03c456; }

.exam-result .sad-face,
.exam-result .laugh-face {
  width: 48px;
  height: 48px;
  display: inline-block;
  vertical-align: top; }

.exam-result .sad-face {
  background: url("/static/web/image/icon_cry.png") center/cover no-repeat; }

.exam-result .laugh-face {
  background: url("/static/web/image/icon_laugh.png") center/cover no-repeat; }

.exam-result .exam-score {
  color: #ff4a18;
  font-size: 38px;
  margin: 15px 0; }

.exam-result .exam-stat {
  font-size: 14px;
  margin-top: 20px; }

.exam-result .exam-pp-info {
  line-height: 24px;
  margin-bottom: 0; }

.exam-result .answer-sheet-warp {
  min-height: 216px; }

.exam-result .asw-ul {
  min-height: 99px; }

.exam-result .asw-st-tt {
  color: #333; }

.exam-result .asw-st-list {
  display: none; }
  .exam-result .asw-st-list.active {
    display: block; }

.exam-result .asw-st-tab {
  margin: 0;
  text-align: left;
  padding: 0 20px; }

.exam-result .st-tab-item {
  padding: 0 10px;
  cursor: pointer;
  display: inline-block; }
  .exam-result .st-tab-item:first-child {
    margin-right: 40px; }
  .exam-result .st-tab-item.active {
    color: #04c557;
    border-bottom: 1px solid #04c557; }

.exam-result .qst-asw-text:disabled,
.exam-result .qst-asw-textarea:disabled {
  background: none; }

.exam-result .err-opt {
  background: url("/static/web/image/icon_wrong2.png") center/cover no-repeat; }

.exam-result .corr-opt {
  background: url("/static/web/image/icon_yes.png") center/cover no-repeat; }

.exam-result .err-opt + span {
  color: #ff4a18; }

.exam-result .corr-opt + span {
  color: #04c557; }

.exam-result .qst-bottom {
  background: #f5f5f5;
  padding: 0 30px; }

.exam-result .qst-asw-warp {
  padding: 14px 0;
  line-height: 24px;
  font-size: 16px;
  border-bottom: 1px solid #dfdfdf; }

.exam-result .icon-qst-del {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
  background: url("/static/web/image/sprite_Personal Center.png") -54px center/auto 19px no-repeat; }

.exam-result .btn-qst-del {
  margin-left: 20px; }

.exam-result .btn-qst-del,
.exam-result .btn-coll {
  float: right;
  border: none;
  background: none; }

.exam-result .icon-coll-star {
  width: 20px;
  height: 20px;
  margin: -2px 3px 0 0;
  vertical-align: middle;
  display: inline-block;
  background: url("/static/web/image/sprite_Personal Center.png") right 0 no-repeat; }

.exam-result .qst-als-cent {
  color: #8e8e8e;
  padding: 20px 0 30px 0; }

.exam-result .qst-als {
  margin-bottom: 20px; }

.exam-result .coll-str {
  display: none; }

.exam-result .collected .not-coll-str {
  display: none; }

.exam-result .collected .coll-str {
  display: inline-block; }

.exam-result .collected .icon-coll-star {
  background-position: -118px 0; }

.exam-result .btn-back {
  line-height: 42px;
  font-size: 16px;
  color: #323232;
  text-align: center;
  border: 1px solid #dcdcdc;
  background: #f4f4f4;
  display: block; }
  .exam-result .btn-back:hover {
    color: #03c456;
    border-color: #03c456;
    background: #ffffff; }
