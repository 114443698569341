@charset "UTF-8";
/* 上半部分模块 */
.pg-member-offline {
  background: #fff;
  /* 提示和银行图片模块 */ }
  .pg-member-offline .package-type-con {
    text-align: left; }
    .pg-member-offline .package-type-con .pay-detail {
      float: left; }
      .pg-member-offline .package-type-con .pay-detail h2.order-number {
        display: block;
        margin: 0;
        font-size: 26px;
        font-weight: normal; }
      .pg-member-offline .package-type-con .pay-detail .tips {
        display: inline-block;
        margin: 10px 0;
        color: #999;
        font-size: 14px; }
      .pg-member-offline .package-type-con .pay-detail p.pay-detail-others {
        color: #666;
        font-size: 16px; }
        .pg-member-offline .package-type-con .pay-detail p.pay-detail-others a {
          color: #666;
          font-size: 16px;
          cursor: pointer;
          text-decoration: underline; }
  .pg-member-offline .package-line-pay.package-type-con {
    padding: 15px 0 0 125px;
    background: #fff url("/static/web/image/icon_success.png") no-repeat 20px 9px;
    width: 1055px;
    height: 105px;
    margin: 0 auto; }
  .pg-member-offline .package-line-pay.package-type-con .really-pay {
    float: right;
    margin: 32px 48px 0 0; }
    .pg-member-offline .package-line-pay.package-type-con .really-pay span {
      font-size: 18px;
      color: #fe9712; }
    .pg-member-offline .package-line-pay.package-type-con .really-pay .package-sum-number {
      font-size: 30px; }
  .pg-member-offline #banks {
    margin: 0 auto;
    width: 1200px; }
  .pg-member-offline #banks .bank-tip {
    margin: 3px 0 16px 0;
    color: #fe9712;
    font-size: 16px;
    font-weight: normal; }
  .pg-member-offline .bank-con {
    width: 530px;
    margin: 50px auto; }
  .pg-member-offline .bank-con .bank-con-detail {
    padding: 45px 0;
    border-bottom: 1px solid #eee; }
  .pg-member-offline .bank-con .bank-img {
    display: inline-block;
    margin: 0 25px 0 20px;
    background: #3dce3d;
    width: 62px;
    height: 62px;
    -moz-border-radius: 50%;
    border-radius: 50%; }
  .pg-member-offline .bank-con .bank-detail {
    display: inline-block;
    font-size: 16px;
    color: #999;
    line-height: 25px; }
  .pg-member-offline .bank-con .bank-con-detail:last-child {
    border: none; }
