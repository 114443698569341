@charset "UTF-8";
/*banner*/
.pg-member-index {
  background: #fff;
  /* banner下方 购买模块 */
  /* 4个服务 */
  /* 套餐购买 */
  /* 会员须知 */ }
  .pg-member-index #member-banner {
    height: 486px;
    background: url("/static/web/image/bg.png") center/cover no-repeat; }
  .pg-member-index .member-banner-con {
    padding: 156px 0 0 60px;
    height: 330px;
    width: 1120px;
    text-align: left; }
  .pg-member-index .member-banner-con-top span {
    color: #fff;
    font-size: 56px;
    display: inline-block;
    height: 80px; }
  .pg-member-index .member-banner-con-top .top-left {
    font-weight: 100; }
  .pg-member-index .member-banner-con-top .top-right {
    font-weight: 900;
    margin-left: 25px; }
  .pg-member-index .member-banner-con-btm {
    color: #fff;
    font-size: 16px;
    font-weight: 100;
    line-height: 30px; }
    .pg-member-index .member-banner-con-btm .outstanding-tip {
      font-weight: 900;
      margin: 0 10px; }
  .pg-member-index .member-pay {
    background: #fff;
    height: 180px;
    position: relative;
    top: -90px;
    -moz-box-shadow: 0 3px 12px rgba(77, 83, 84, 0.1);
    box-shadow: 0 3px 12px rgba(77, 83, 84, 0.1);
    -moz-border-radius: 4px;
    border-radius: 4px; }
  .pg-member-index .member-pay-left {
    margin-left: 60px;
    float: left;
    padding-top: 38px;
    height: 108px; }
    .pg-member-index .member-pay-left .company-img {
      float: left;
      margin-right: 15px;
      width: 108px;
      height: 108px;
      -moz-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("/static/web/image/bg.png");
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: #fff; }
    .pg-member-index .member-pay-left .company-name {
      float: left;
      height: 108px;
      text-align: left; }
  .pg-member-index .company-name .name {
    font-size: 20px;
    text-align: left;
    display: block;
    line-height: 30px; }
  .pg-member-index .company-name .type {
    display: block;
    margin: 10px 0 15px 0;
    background: #3dce3d;
    max-width: 160px;
    min-width: 100px;
    width: auto;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    -moz-border-radius: 15px;
    border-radius: 15px;
    font-size: 16px;
    text-align: center; }
  .pg-member-index .company-name .time {
    font-size: 14px;
    color: #666; }
  .pg-member-index .member-pay-right {
    float: right;
    padding-top: 70px;
    height: 94px;
    margin-right: 60px; }
    .pg-member-index .member-pay-right .buy,
    .pg-member-index .member-pay-right .give {
      display: inline-block;
      color: #ff9d07;
      margin-right: 30px; }
    .pg-member-index .member-pay-right .give {
      color: #666; }
    .pg-member-index .member-pay-right .money-number {
      display: block;
      font-size: 34px;
      text-align: center;
      height: 34px; }
    .pg-member-index .member-pay-right .number {
      font-size: 16px; }
    .pg-member-index .member-pay-right .member {
      background: #ff9d07;
      width: 204px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border: none;
      font-size: 24px;
      color: #fff;
      -moz-border-radius: 4px;
      border-radius: 4px;
      position: relative;
      top: -18px;
      cursor: pointer; }
  .pg-member-index #server-con {
    width: 850px;
    margin: 0 auto; }
  .pg-member-index #server-con .servers {
    margin-bottom: 108px;
    overflow: hidden;
    width: 100%;
    height: 240px; }
  .pg-member-index .servers .servers-descript {
    float: left;
    width: 360px;
    height: 240px; }
  .pg-member-index .servers .servers-descript .servers-descript-top {
    padding: 50px 0 0 90px;
    margin: 0 auto;
    font-size: 36px;
    height: 60px;
    line-height: 60px;
    position: relative;
    top: 50px;
    opacity: 0;
    text-align: left;
    -webkit-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98);
    -o-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98);
    -moz-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98);
    transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98); }
  .pg-member-index .servers .servers-descript .servers-descript-btm {
    font-size: 18px;
    color: #666;
    text-align: center;
    line-height: 30px;
    position: relative;
    top: 50px;
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98) 0.2s;
    -o-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98) 0.2s;
    -moz-transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98) 0.2s;
    transition: all 0.6s cubic-bezier(0.25, 0.46, 0.33, 0.98) 0.2s; }
  .pg-member-index .servers .servers-img {
    float: left;
    margin-left: 108px;
    position: relative;
    top: 60px;
    opacity: 0;
    -webkit-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.33, 0.98);
    -o-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.33, 0.98);
    -moz-transition: all 0.8s cubic-bezier(0.25, 0.46, 0.33, 0.98);
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.33, 0.98); }
  .pg-member-index .servers:nth-child(2) .servers-img {
    margin-left: 0;
    margin-right: 108px; }
  .pg-member-index #server-con .servers:nth-child(4) .servers-img {
    margin-left: 0;
    margin-right: 108px; }
  .pg-member-index .member-buy-banner {
    width: 100%;
    background: url("/static/web/image/bg1.png") no-repeat;
    -moz-background-size: contain;
    background-size: contain; }
  .pg-member-index .member-list {
    display: inline-block;
    width: 890px; }
  .pg-member-index .member-item {
    display: inline-block;
    margin: 50px 0 0 0;
    background: #fff;
    width: 360px;
    height: 468px;
    border: 1px solid #81c742;
    text-align: left;
    vertical-align: top; }
  .pg-member-index .member-item:nth-of-type(even) {
    margin: 50px  0 0 155px;
    border: 1px solid #f3b113; }
    .pg-member-index .member-item:nth-of-type(even) .member-item-name,
    .pg-member-index .member-item:nth-of-type(even) .member-buy-btn {
      background: #f3b113; }
  .pg-member-index .member-item-name {
    background: #81c742;
    width: 360px;
    text-align: center;
    line-height: 60px;
    height: 60px;
    color: #fff;
    font-size: 24px; }
  .pg-member-index .member-item-price {
    margin: 35px 0 25px 0;
    font-size: 36px;
    font-weight: bold;
    width: 100%;
    text-align: center; }
    .pg-member-index .member-item-price .price-unit {
      font-weight: 100;
      font-size: 18px; }
  .pg-member-index .member-item-descript {
    padding: 0 55px;
    line-height: 32px;
    font-size: 16px;
    height: 220px;
    text-align: left;
    overflow: hidden; }
  .pg-member-index .member-buy-btn {
    background: #81c742;
    margin: 10px 0 0 90px;
    border: none;
    font-size: 14px;
    color: #fff;
    width: 180px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer; }
  .pg-member-index .member-item .member-buy-btn:disabled {
    background: #ddd; }
  .pg-member-index #member-knews {
    padding: 15px 0;
    margin: 100px auto 60px auto;
    width: 1180px;
    text-align: center;
    -moz-box-shadow: 0 3px 12px rgba(77, 83, 84, 0.1);
    box-shadow: 0 3px 12px rgba(77, 83, 84, 0.1); }
  .pg-member-index .member-knews-title {
    font-size: 16px;
    color: #222;
    margin: 12px 0 7px 0; }
  .pg-member-index .member-knews-list {
    padding-left: 400px;
    width: 800px;
    color: #666;
    font-size: 14px;
    text-align: left;
    line-height: 26px; }
