@charset "UTF-8";
.pg-register-update {
  background: #F1F1F1;
  /*完善注册*/
  /* 加号 */
  /* 行业 */
  /* 省份 */
  /* 材料 */
  /* 底部按钮 */
  /* 底部信息 */
  /* 审核 */
  /* 审核中 */ }
  .pg-register-update .reg-right {
    float: right;
    background: #fff;
    width: 500px;
    height: 790px; }
    .pg-register-update .reg-right .reg-success-img {
      margin-top: 50px; }
    .pg-register-update .reg-right .reg-tip {
      line-height: 66px;
      font-size: 26px;
      font-weight: 100; }
    .pg-register-update .reg-right .reg-tips {
      margin: 0 0 60px 42px;
      text-align: left;
      font-size: 16px;
      font-weight: normal;
      color: #ff4918; }
  .pg-register-update .reg-middle {
    padding: 0 42px;
    text-align: left; }
    .pg-register-update .reg-middle li.required {
      position: relative; }
    .pg-register-update .reg-middle li.required:after {
      display: block;
      position: absolute;
      left: 7px;
      top: 4px;
      content: "*";
      color: #ff4918;
      width: 20px;
      font-size: 16px; }
    .pg-register-update .reg-middle .place.required:after {
      top: 17px; }
    .pg-register-update .reg-middle .proof-material.required:after {
      left: 5px;
      top: 4px; }
    .pg-register-update .reg-middle .required-tip {
      color: #ff4918;
      font-size: 14px;
      position: absolute;
      display: none; }
  .pg-register-update .industry-wrapper {
    display: inline-block;
    margin: 25px 0 20px 0;
    padding: 0 100px 0 20px;
    position: relative;
    height: 42px;
    width: 294px;
    line-height: 42px;
    border: 1px solid  #eee;
    color: #8e8e8e;
    font-size: 14px; }
    .pg-register-update .industry-wrapper .add-industry {
      position: absolute;
      color: #04c557;
      right: 10px;
      text-decoration: underline;
      cursor: pointer; }
  .pg-register-update .industry-wrapper.active {
    border: 1px solid  #ff4918;
    background: #fff7f7; }
    .pg-register-update .industry-wrapper.active .required-tip {
      display: inline-block;
      top: 32px;
      right: 0; }
  .pg-register-update .place-wrapper {
    display: inline-block;
    position: relative; }
  .pg-register-update .province-wrapper.active .province {
    background: #fff7f7;
    border: 1px solid #ff4918; }
  .pg-register-update .city-wrapper.active .city {
    background: #fff7f7;
    border: 1px solid #ff4918; }
  .pg-register-update .province-wrapper.active .required-tip {
    display: block;
    right: 16px;
    top: 50px; }
  .pg-register-update .city-wrapper.active .required-tip {
    display: block;
    right: 16px;
    top: 50px; }
  .pg-register-update .city-wrapper.active .required-tip {
    right: 0; }
  .pg-register-update .place .province {
    padding-left: 20px;
    background: #fff;
    border: 1px solid #eee;
    color: #8e8e8e;
    outline: none;
    width: 196px;
    height: 42px; }
  .pg-register-update .place .city {
    padding-left: 20px;
    background: #fff;
    border: 1px solid #eee;
    color: #8e8e8e;
    outline: none;
    width: 196px;
    height: 42px; }
  .pg-register-update .industry-tip {
    font-size: 14px;
    color: #8e8e8e; }
  .pg-register-update .place.focus .province {
    background: none;
    border: 1px solid #04c557; }
  .pg-register-update .place.focus .city {
    background: none;
    border: 1px solid #04c557; }
  .pg-register-update .place .province {
    margin-right: 18px; }
  .pg-register-update .material-wrapper {
    height: 210px; }
    .pg-register-update .material-wrapper .upload-img {
      width: 100%;
      height: 100%; }
    .pg-register-update .material-wrapper .ke-upload-file {
      cursor: pointer;
      width: 196px;
      height: 196px;
      z-index: 1 !important; }
    .pg-register-update .material-wrapper .ke-button-common {
      width: 196px;
      height: 196px;
      opacity: 0; }
  .pg-register-update .proof-material {
    margin-top: 46px; }
    .pg-register-update .proof-material h4.title {
      padding-left: 20px;
      line-height: 20px;
      color: #8e8e8e;
      font-size: 14px;
      font-weight: 100; }
    .pg-register-update .proof-material .business-photo,
    .pg-register-update .proof-material .company-logo {
      float: left;
      position: relative;
      width: 196px;
      height: 196px;
      cursor: pointer;
      background: #eee url("/static/web/image/add_img.png") no-repeat center center; }
    .pg-register-update .proof-material .business-photo {
      margin-right: 18px; }
    .pg-register-update .proof-material .business-photo.active,
    .pg-register-update .proof-material .company-logo.active {
      background: #fff7f7 url("/static/web/image/add_img.png") no-repeat center center;
      border: 1px solid #ff4918;
      width: 194px; }
    .pg-register-update .proof-material .business-photo.active .required-tip,
    .pg-register-update .proof-material .company-logo.active .required-tip {
      display: block;
      right: 0;
      bottom: -28px; }
  .pg-register-update .business-photo .photo-tip {
    position: absolute;
    bottom: 30px;
    left: 56px;
    font-size: 14px;
    color: #8e8e8e; }
  .pg-register-update .company-logo .logo-tip {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #8e8e8e; }
  .pg-register-update .reg-relate-btn {
    margin-top: 46px; }
    .pg-register-update .reg-relate-btn .btn {
      background: #04c557;
      border: none;
      outline: none;
      font-size: 20px;
      width: 196px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      cursor: pointer; }
    .pg-register-update .reg-relate-btn .no-do-btn {
      background: #eee;
      color: #888;
      margin-left: 18px; }
  .pg-register-update .reg-footer {
    margin-top: 20px;
    text-align: center; }
    .pg-register-update .reg-footer .login-con {
      display: inline-block;
      padding-right: 20px;
      font-size: 14px;
      color: #8e8e8e;
      height: 14px;
      line-height: 14px;
      border-right: 1px solid #8e8e8e; }
      .pg-register-update .reg-footer .login-con .login {
        color: #04c557; }
    .pg-register-update .reg-footer .back-main {
      display: inline-block;
      padding-left: 20px;
      font-size: 14px;
      cursor: pointer;
      color: #8e8e8e; }
  .pg-register-update .register-audit-wp {
    margin: 30px 0;
    background: #fff; }
  .pg-register-update .register-audit {
    margin: 0 auto;
    width: 416px; }
  .pg-register-update .name-wrapper {
    margin-top: 40px;
    height: 44px; }
    .pg-register-update .name-wrapper .tenant-name {
      padding: 0 20px;
      width: 373px;
      height: 44px;
      color: #8e8e8e;
      border: 1px solid #eee;
      font-size: 14px; }
  .pg-register-update .name-wrapper.active .required-tip {
    display: block; }
  .pg-register-update .name-wrapper.active .tenant-name {
    background: #fff7f7;
    border: 1px solid #ff4918; }
  .pg-register-update .name-wrapper:after {
    top: 18px !important; }
  .pg-register-update .submit-btn-wp {
    text-align: center; }
    .pg-register-update .submit-btn-wp .submit-btn {
      display: inline-block;
      margin: 30px 0;
      background: #04c557;
      width: 198px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      font-size: 20px;
      border: none;
      cursor: pointer;
      outline: none; }
  .pg-register-update .perfect-tip {
    display: inline-block;
    padding: 0 40px  0 85px;
    margin-top: 40px;
    background: #fffbf2 url("/static/web/image/icon1.png") no-repeat 40px 15px;
    height: 60px;
    line-height: 60px;
    border: 1px solid #fc930d;
    color: #ffa71d;
    font-size: 16px; }
  .pg-register-update .fail-perfect-tip {
    display: inline-block;
    padding: 20px 40px  20px 85px;
    margin-top: 40px;
    background: #fff7f7 url("/static/web/image/icon2.png") no-repeat 40px 23px;
    max-width: 600px;
    min-height: 30px;
    line-height: 30px;
    border: 1px solid #ff4a18;
    color: #ff4918;
    font-size: 16px; }
  .pg-register-update .register-auditing-tip {
    display: inline-block;
    padding: 0 30px  0 75px;
    margin: 40px 0;
    background: #edfafe url("/static/web/image/icon3.png") no-repeat 40px 15px;
    height: 60px;
    line-height: 60px;
    border: 1px solid #7dcef2;
    color: #176bea;
    font-size: 16px; }
  .pg-register-update .audit-item {
    padding: 0 20px;
    margin-bottom: 20px;
    width: 374px;
    height: 38px;
    line-height: 38px;
    color: #8e8e8e;
    border: 1px solid #eee;
    font-size: 14px;
    text-align: left; }
  .pg-register-update .audit-place {
    margin-bottom: 20px;
    height: 40px; }
    .pg-register-update .audit-place .place-item {
      float: left;
      padding: 0 20px;
      width: 156px;
      height: 38px;
      line-height: 38px;
      border: 1px solid #eeeeee;
      color: #8e8e8e;
      font-size: 14px;
      text-align: left; }
    .pg-register-update .audit-place .place-item:nth-child(1) {
      margin-right: 20px; }
  .pg-register-update .audit-materail {
    height: 400px; }
  .pg-register-update .audit-materail-title {
    margin: 10px 0 20px 15px;
    font-size: 14px;
    color: #8e8e8e;
    text-align: left; }
  .pg-register-update .audit-license {
    margin-right: 20px; }
  .pg-register-update .audit-license,
  .pg-register-update .audit-logo {
    float: left;
    width: 196px;
    color: #8e8e8e;
    font-size: 14px;
    text-align: center; }
  .pg-register-update .audit-license-img,
  .pg-register-update .audit-logo-img {
    margin-bottom: 20px;
    height: 196px;
    width: 196px; }
