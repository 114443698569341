@charset "UTF-8";
.pg-teacher-list {
  background: #f1f1f1;
  /* 显示更多 按钮样式*/ }
  .pg-teacher-list .teacher-list-banner {
    width: 100%;
    height: 524px;
    background: url("/static/web/image/teacher_banner.jpg") center/cover no-repeat;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/teacher_banner.jpg',sizingMethod='scale'); }
  .pg-teacher-list .teacher-banner-descrip-wp {
    padding-left: 30px;
    text-align: left; }
  .pg-teacher-list .teacher-banner-descrip-top {
    margin-top: 208px; }
    .pg-teacher-list .teacher-banner-descrip-top .teacher-type {
      width: 152px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      font-size: 20px;
      color: #fff;
      border: 1px solid #04c557; }
    .pg-teacher-list .teacher-banner-descrip-top .teacher-type-descrip {
      margin: 20px 0 60px 0;
      width: 404px;
      height: 60px;
      font-size: 14px;
      color: #9197af;
      line-height: 20px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3; }
  .pg-teacher-list .teacher-banner-descrip-btm .teacher-example {
    padding-left: 25px;
    position: relative;
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    color: #fff; }
  .pg-teacher-list .teacher-banner-descrip-btm .teacher-example:after {
    display: block;
    position: absolute;
    left: 0;
    top: 1px;
    content: '';
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-left: 10px solid #04c557;
    border-bottom: 6px solid transparent; }
  .pg-teacher-list .teacher-banner-descrip-btm .teacher-type-descrip {
    margin-top: 14px;
    padding-left: 25px;
    width: 370px;
    height: 54px;
    font-size: 12px;
    color: #9197af;
    line-height: 18px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .pg-teacher-list .teacher-list-con {
    padding: 40px 0; }
    .pg-teacher-list .teacher-list-con .switch-btns {
      height: 42px;
      text-align: left; }
      .pg-teacher-list .teacher-list-con .switch-btns button.btn {
        width: 220px;
        height: 42px;
        color: #323232;
        text-align: center;
        background: #fff;
        outline: none;
        line-height: 42px;
        font-size: 18px;
        margin-right: 10px;
        border: 1px solid #d2d2d2;
        cursor: pointer; }
      .pg-teacher-list .teacher-list-con .switch-btns button:not(.active):hover {
        border: 1px solid #00a0e9;
        color: #00a0e9; }
      .pg-teacher-list .teacher-list-con .switch-btns button.active {
        background: #04c557;
        color: #fff;
        border: none;
        position: relative; }
      .pg-teacher-list .teacher-list-con .switch-btns button.active:after {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #04c557;
        position: absolute;
        left: 105px;
        top: 41px;
        content: ''; }
  .pg-teacher-list .teacher-list-wrapper {
    display: none;
    padding-bottom: 70px;
    background: #fff;
    margin-top: 20px; }
    .pg-teacher-list .teacher-list-wrapper .teacher-describe {
      padding: 10px 0 0 20px;
      line-height: 24px;
      font-size: 14px;
      color: #8e8e8e;
      text-align: left; }
  .pg-teacher-list .teacher-list-wrapper.active {
    display: block; }
  .pg-teacher-list .teacher-item-mask {
    background: rgba(0, 0, 0, 0.8);
    filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr=#c8000000,endColorstr=#c8000000);
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    top: 0;
    left: 0; }
  .pg-teacher-list .teacher-list.profress .teacher-item {
    background: #fbfbfb url("/static/web/image/default_img.png") no-repeat center center;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/default_img.png',sizingMethod='scale'); }
    .pg-teacher-list .teacher-list.profress .teacher-item .lk-teacher {
      color: #00a0e9;
      border-bottom: 1px solid #00a0e9; }
  .pg-teacher-list .teacher-list {
    margin: 0;
    padding: 0 0 0 12px;
    overflow: auto;
    text-align: left; }
    .pg-teacher-list .teacher-list .teacher-item {
      display: inline-block;
      margin: 14px 0 0 8px;
      padding: 0;
      width: 218px;
      height: 218px;
      text-align: center;
      position: relative; }
      .pg-teacher-list .teacher-list .teacher-item .lk-teacher {
        display: inline-block;
        padding: 0 10px 10px 10px;
        margin: 66px auto 20px auto;
        font-size: 20px;
        color: #04c557;
        min-width: 40px;
        border-bottom: 1px solid #04c557; }
      .pg-teacher-list .teacher-list .teacher-item .teacher-school,
      .pg-teacher-list .teacher-list .teacher-item .teacher-degree {
        display: block;
        color: #bbb;
        font-size: 14px; }
  .pg-teacher-list .teacher-list.more {
    height: 1032px;
    overflow: hidden; }
  .pg-teacher-list .teacher-item:hover .teacher-item-mask {
    display: block;
    cursor: pointer; }
  .pg-teacher-list .tc-it-link {
    display: block;
    width: 100%;
    height: 100%; }
  .pg-teacher-list .teacher-list-wrapper .show-more {
    display: none; }
  .pg-teacher-list .teacher-list-wrapper .show-more.active {
    display: block;
    margin: 30px auto 0 auto;
    width: 220px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    outline: none;
    color: #fff;
    background: #04c557;
    border: none;
    font-size: 18px;
    cursor: pointer; }
