.pg-aboutus-index {
  background: #fff; }
  .pg-aboutus-index .about-us-banner .about-us-banner-a {
    background: url("/static/web/image/about_us_a.jpg") no-repeat center/cover;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/about_us_a.jpg',sizingMethod='scale');
    height: 566px;
    width: 100%; }
  .pg-aboutus-index .about-us-banner .about-us-banner-b {
    background: url("/static/web/image/about_us_b.jpg") no-repeat center/cover;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/about_us_b.jpg',sizingMethod='scale');
    height: 785px;
    width: 100%; }
  .pg-aboutus-index .about-us-banner .about-us-banner-c {
    background: url("/static/web/image/about_us_c.jpg") no-repeat center/cover;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/about_us_c.jpg',sizingMethod='scale');
    height: 585px;
    width: 100%; }
  .pg-aboutus-index .about-us-banner .about-us-banner-d {
    background: url("/static/web/image/about_us_d.jpg") no-repeat center/cover;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/about_us_d.jpg',sizingMethod='scale');
    height: 782px;
    width: 100%; }
  .pg-aboutus-index .about-us-banner .about-us-banner-e {
    background: url("/static/web/image/about_us_e.jpg") no-repeat center/cover;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/about_us_e.jpg',sizingMethod='scale');
    height: 800px;
    width: 100%; }
  .pg-aboutus-index .about-us-banner .about-us-banner-f {
    background: url("/static/web/image/about_us_f.jpg") no-repeat center/cover;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/about_us_f.jpg',sizingMethod='scale');
    height: 799px;
    width: 100%; }
  .pg-aboutus-index .about-us-banner .about-us-banner-g {
    background: url("/static/web/image/about_us_g.jpg") no-repeat center/cover;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/static/web/image/about_us_g.jpg',sizingMethod='scale');
    height: 769px;
    width: 100%; }
