@charset "UTF-8";
/* 微信充值 */
.pg-member-weixin {
  background: #fff; }
  .pg-member-weixin .package-order {
    padding-top: 50px;
    height: 70px; }
  .pg-member-weixin .order-number {
    display: inline-block;
    margin-left: 64px;
    font-size: 26px;
    font-weight: normal; }
  .pg-member-weixin .package-order .really-pay {
    float: right;
    margin-right: 48px; }
    .pg-member-weixin .package-order .really-pay span {
      font-size: 18px;
      color: #fe9712; }
    .pg-member-weixin .package-order .really-pay .package-sum-number {
      font-size: 30px; }
  .pg-member-weixin #order-qrcode {
    margin: 0 auto;
    width: 1180px;
    font-size: 14px; }
    .pg-member-weixin #order-qrcode .order-tip {
      margin: 0 0 30px 65px;
      color: #999;
      line-height: 30px;
      text-align: left; }
    .pg-member-weixin #order-qrcode .back-change-pay {
      color: #666;
      text-decoration: underline; }
    .pg-member-weixin #order-qrcode .please {
      margin: 15px 0 35px 0;
      text-align: center;
      font-size: 16px;
      color: #666; }
