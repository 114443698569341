@charset "UTF-8";
.pg-course-play {
  background: #f1f1f1;
  /* 播放 */
  /* 播放列表 */
  /* 评论 */
  /* 他们也在学 */ }
  .pg-course-play .video-play-con {
    margin-bottom: 20px;
    background: #000;
    height: 580px; }
    .pg-course-play .video-play-con .bd-cnt {
      margin: 34px 0 46px 0; }
  .pg-course-play .player-wp {
    display: inline-block;
    margin-right: 20px;
    width: 844px;
    height: 500px;
    text-align: left;
    vertical-align: top; }
    .pg-course-play .player-wp .play-video-btn {
      margin: 220px 0 0 404px;
      background: url("/static/web/image/course_detail_search_icon.png") -135px 1px no-repeat;
      width: 60px;
      height: 60px;
      border: none;
      cursor: pointer; }
  .pg-course-play .play-catelog {
    display: inline-block;
    background: #1b1b1b;
    width: 310px;
    height: 500px;
    overflow-y: auto;
    text-align: left;
    vertical-align: top; }
  .pg-course-play .catelog-title {
    display: inline-block;
    padding: 20px 30px 14px 30px;
    position: relative;
    z-index: 2;
    font-size: 18px;
    font-weight: normal;
    color: #8e8e8e;
    border-bottom: 2px solid #04c557; }
  .pg-course-play .play-chapter-item {
    position: relative;
    top: -2px;
    border-top: 2px solid #313131; }
    .pg-course-play .play-chapter-item .chapter-item-name {
      padding: 0 20px;
      height: 42px;
      line-height: 42px;
      font-weight: normal;
      font-size: 14px;
      color: #bbb;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .pg-course-play .play-section-item {
    padding: 0 55px 0 50px;
    position: relative;
    height: 42px;
    width: 193px;
    line-height: 42px;
    font-weight: normal;
    font-size: 12px;
    color: #7b7b7b;
    cursor: pointer;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .pg-course-play .play-section-item .order {
      display: inline-block;
      position: absolute;
      top: 13px;
      left: 20px;
      height: 18px;
      line-height: 18px;
      min-width: 25px; }
    .pg-course-play .play-section-item .play-btn {
      display: none;
      position: absolute;
      top: 11px;
      left: 20px;
      width: 18px;
      height: 18px;
      background: url("/static/web/image/course_detail_search_icon.png") 0 -22px no-repeat; }
    .pg-course-play .play-section-item .status {
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 5px;
      top: 0;
      background: none; }
    .pg-course-play .play-section-item .status.fail {
      background: url("/static/web/image/icon_not pass.png") 0 12px no-repeat; }
    .pg-course-play .play-section-item .status.success {
      background: url("/static/web/image/icon_adopt.png") 0 12px no-repeat; }
    .pg-course-play .play-section-item .status.lock {
      background: #272727 url("/static/web/image/icon_lock.png") 3px 13px no-repeat; }
  .pg-course-play .play-section-item:hover {
    background: #272727; }
  .pg-course-play .play-section-item.active {
    color: #04c557; }
    .pg-course-play .play-section-item.active .play-btn {
      display: inline-block; }
    .pg-course-play .play-section-item.active .order {
      display: none; }
  .pg-course-play .play-video-title {
    padding: 20px 0 10px 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 18px;
    font-weight: normal;
    color: #323232; }
  .pg-course-play .play-video-evalute-con {
    padding: 0 0 20px 15px;
    border-bottom: 1px solid #e5e5e5; }
    .pg-course-play .play-video-evalute-con .evalute-person-head {
      display: inline-block;
      width: 52px;
      height: 52px;
      -moz-border-radius: 50%;
      border-radius: 50%;
      vertical-align: top; }
    .pg-course-play .play-video-evalute-con .evalute-wp {
      display: inline-block;
      margin-left: 20px;
      vertical-align: top;
      width: 693px; }
  .pg-course-play .evalute-descript {
    padding: 10px;
    background: #f5f5f5;
    width: 673px;
    height: 52px;
    color: #8e8e8e;
    resize: none;
    border: 1px solid #e5e5e5; }
  .pg-course-play .evalute-btm .evalute-grade {
    display: inline-block;
    padding-top: 15px; }
    .pg-course-play .evalute-btm .evalute-grade .evalute-tip {
      display: inline-block;
      margin-left: 10px;
      line-height: 29px;
      font-size: 14px;
      color: #323232;
      vertical-align: top; }
  .pg-course-play .evalute-btm .evalute-grade-list {
    display: inline-block;
    vertical-align: top; }
  .pg-course-play .evalute-btm .evalute-grade-item {
    display: inline-block;
    cursor: pointer;
    background: url("/static/web/image/course_detail_search_icon.png") -29px -18px no-repeat;
    width: 25px;
    height: 25px; }
  .pg-course-play .evalute-btm .evalute-grade-item.gray {
    background: url("/static/web/image/course_detail_search_icon.png") -57px -18px no-repeat; }
  .pg-course-play .evalute-btm .evalute-btn {
    float: right;
    margin-top: 8px;
    background: #f5f5f5;
    width: 134px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    font-size: 16px;
    border: 1px solid #e5e5e5;
    color: #323232;
    cursor: pointer; }
  .pg-course-play .study-person-list {
    padding-left: 11px; }
  .pg-course-play .study-person-item {
    display: inline-block;
    margin-right: 11px;
    background: url("/static/web/image/course_detail_test.jpg") center/cover no-repeat;
    width: 52px;
    height: 52px;
    -moz-border-radius: 50%;
    border-radius: 50%; }
  .pg-course-play .ency-md-tit {
    padding: 20px 0 10px 10px;
    margin-bottom: 20px;
    font-weight: normal;
    border-bottom: 2px solid #f1f1f1; }
    .pg-course-play .ency-md-tit .md-tit-name {
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      color: #323232; }
  .pg-course-play .course-no-data {
    font-size: 20px;
    color: #8e8e8e;
    margin: 100px auto;
    text-align: center; }
  .pg-course-play .study-no-data {
    padding: 40px 0;
    text-align: center;
    font-size: 16px;
    color: #8e8e8e; }
