@charset "UTF-8";
/* 顶部 */
.pg-teacher-detail {
  background: #f1f1f1; }
  .pg-teacher-detail .teacher-detail-top {
    margin: 40px 0  10px 0;
    height: 210px;
    overflow: hidden; }
    .pg-teacher-detail .teacher-detail-top .teacher-photo {
      float: left;
      background: #636c7c url("/static/web/image/default_img.png") no-repeat center center;
      width: 190px;
      height: 210px;
      border-right: 2px solid #636c7c; }
  .pg-teacher-detail .teacher-base-info-wp {
    float: left;
    position: relative;
    width: 988px;
    vertical-align: top; }
    .pg-teacher-detail .teacher-base-info-wp .base-info-back {
      position: absolute;
      top: 0;
      left: 0;
      height: 210px;
      width: 100%;
      background: #3a4a51; }
  .pg-teacher-detail .teacher-base-info {
    position: absolute;
    padding-left: 40px;
    top: 0;
    left: 0;
    width: 943px;
    height: 100%;
    text-align: left; }
    .pg-teacher-detail .teacher-base-info .teacher-name {
      margin-top: 0;
      padding: 30px 0 5px 0;
      font-size: 24px;
      font-weight: normal;
      color: #fff; }
    .pg-teacher-detail .teacher-base-info .teacher-title {
      color: #bbb;
      font-size: 14px;
      font-weight: normal; }
    .pg-teacher-detail .teacher-base-info .teacher-nick {
      display: inline-block;
      margin: 17px 0 25px 0;
      padding: 0 5px;
      background: #04c356;
      min-width: 65px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      color: #fff;
      font-size: 14px; }
    .pg-teacher-detail .teacher-base-info .teacher-grade {
      color: #fff;
      font-size: 16px; }
      .pg-teacher-detail .teacher-base-info .teacher-grade span {
        display: inline-block;
        padding: 0 8px;
        height: 16px;
        line-height: 16px; }
      .pg-teacher-detail .teacher-base-info .teacher-grade .teacher-class-hours {
        padding-left: 0; }
      .pg-teacher-detail .teacher-base-info .teacher-grade .teacher-study-times {
        border-left: 2px solid #fff;
        border-right: 2px solid #fff; }
    .pg-teacher-detail .teacher-base-info .teacher-follow-btn {
      background: #00a0e9;
      position: absolute;
      right: 70px;
      top: 86px;
      width: 138px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      font-size: 16px;
      border: none;
      cursor: pointer; }
    .pg-teacher-detail .teacher-base-info .teacher-follow-btn.cancle {
      background: #e2e2e2;
      color: #323232; }
  .pg-teacher-detail .teacher-educate-list {
    padding: 30px;
    background: #fff;
    width: 1120px;
    text-align: left; }
  .pg-teacher-detail .educate-item-title {
    padding-left: 20px;
    position: relative;
    height: 20px;
    line-height: 20px;
    color: #00a0e9;
    font-size: 16px;
    font-weight: normal; }
  .pg-teacher-detail .educate-item-title:after {
    display: block;
    background: #00a0e9;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 4px;
    content: ''; }
  .pg-teacher-detail .educate-item-descript {
    padding: 14px 0 24px 20px;
    font-size: 14px;
    color: #323232;
    line-height: 26px; }
  .pg-teacher-detail .teacher-educate-item:last-child .educate-item-descript {
    padding-bottom: 0; }
  .pg-teacher-detail .teacher-detail-btm {
    margin-bottom: 30px;
    text-align: left; }
    .pg-teacher-detail .teacher-detail-btm .course-list {
      overflow: auto; }
    .pg-teacher-detail .teacher-detail-btm .cnt-list {
      overflow: hidden; }
    .pg-teacher-detail .teacher-detail-btm .main-course {
      margin-top: 40px;
      height: 20px;
      line-height: 20px;
      font-size: 20px;
      font-weight: normal;
      color: #323232; }
  .pg-teacher-detail .teacher-study-times {
    color: #eee; }
  .pg-teacher-detail .course-hour {
    margin-right: 4px; }
  .pg-teacher-detail .star-rating {
    width: 114px; }
