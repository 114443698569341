@charset "UTF-8";
/* 活动、资讯、百科详情的左边 */
/* 顶部样式 */
.ency-list-lf .item-hours .hours-icon {
  display: inline-block;
  padding-top: 6px;
  background: url("/static/web/image/sprite_more_information.png") no-repeat -44px 0;
  position: relative;
  top: 10px;
  width: 20px;
  height: 16px; }

.ency-list-lf .item-times .times-icon {
  display: inline-block;
  padding-top: 6px;
  background: url("/static/web/image/sprite_more_information.png") no-repeat -44px 0;
  position: relative;
  top: 10px;
  width: 20px;
  height: 16px; }

.ency-list-lf .item-hours {
  display: inline-block;
  height: 18px;
  color: #bbb;
  font-size: 12px;
  cursor: pointer; }

.ency-list-lf .item-times {
  display: inline-block;
  height: 18px;
  color: #bbb;
  font-size: 12px;
  cursor: pointer; }

.ency-list-lf .item-hours:hover .hours-icon {
  background-position: -1px 0; }

.ency-list-lf .item-times .times-icon {
  background-position: -65px 0; }

.ency-list-lf .item-times:hover .times-icon {
  background-position: -20px 0; }

/* 中间描述 */
.infor-detail {
  margin-top: 30px; }

.infor-detail-times {
  padding: 40px 0 10px 0;
  text-align: right;
  border-bottom: 1px solid #f1f1f1; }

.infor-detail-times .item-times {
  margin-left: 20px; }

.activity-detail-wp {
  padding: 0 20px;
  border-bottom: 1px solid #eeeeee; }
  .activity-detail-wp .detail-name {
    padding: 40px 0;
    font-size: 24px;
    font-weight: normal;
    text-align: center; }
  .activity-detail-wp .detail-banner {
    background: #eee;
    width: 100%;
    min-height: 300px;
    height: auto; }
  .activity-detail-wp .detail-descript {
    padding: 20px 0 40px 0;
    font-size: 16px;
    color: #8e8e8e;
    word-break: break-all; }
  .activity-detail-wp img {
    max-width: 100%; }

/* 标签 */
.infor-label-cn {
  padding: 0 20px; }
  .infor-label-cn .infor-label-tit {
    padding: 15px 0 5px 0;
    font-size: 16px;
    font-weight: normal;
    color: #323232; }
  .infor-label-cn .infor-label-list {
    margin-bottom: 45px; }
  .infor-label-cn .label-item {
    display: inline-block;
    padding: 10px 20px 0 0;
    font-size: 14px; }
  .infor-label-cn .label-item.no-data {
    color: #888; }

/* 底部切换按钮 */
.detail-switch-wp {
  margin-bottom: 30px;
  height: 70px; }
  .detail-switch-wp .btns-wp {
    float: left;
    color: #323232;
    padding: 15px 20px;
    margin-left: 20px;
    background: #eee;
    border: none;
    width: 300px;
    line-height: 30px;
    text-align: left;
    cursor: pointer; }
  .detail-switch-wp .btns-wp:nth-child(2) {
    margin: 0 20px 0 40px; }
  .detail-switch-wp .btns-wp.next-wp {
    text-align: right; }
    .detail-switch-wp .btns-wp.next-wp .swit-btn {
      float: right; }
  .detail-switch-wp .btns-wp .swit-btn {
    float: left;
    width: 80px;
    text-align: center; }
  .detail-switch-wp .btns-wp .name {
    float: left;
    width: 200px;
    text-align: left;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; }
