.container-guide {
  background: #fff; }
  .container-guide .guide-banner {
    height: 400px;
    background: url("/static/web/image/banner_guide.png") center no-repeat; }
  .container-guide .tab-menu {
    text-align: left;
    margin-top: 260px;
    padding: 0 80px; }
  .container-guide .btn-type-guide {
    width: 200px;
    height: 50px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid #03c456;
    background: none;
    color: #03c456;
    margin-right: 15px;
    font-size: 20px; }
    .container-guide .btn-type-guide.active {
      color: #fff;
      background: #03c456; }
  .container-guide .tab-submenu {
    margin: 50px 0;
    text-align: left; }
  .container-guide .btn-submenu {
    width: 200px;
    height: 60px;
    font-size: 20px;
    border: none;
    color: #4c4c4c;
    display: inline-block;
    margin: 10px 10px 30px 10px;
    background: url("/static/web/image/bg_btn_nomal.png") center no-repeat; }
    .container-guide .btn-submenu.active {
      color: #fff;
      background: url("/static/web/image/bg_btn_active.png") center no-repeat; }
  .container-guide .guide-item {
    display: none; }
    .container-guide .guide-item.active {
      display: block; }
  .container-guide .guide-list {
    margin: 0 0 40px 0; }
  .container-guide .guide-warp-sdt,
  .container-guide .guide-warp-adm {
    display: none; }
    .container-guide .guide-warp-sdt.active,
    .container-guide .guide-warp-adm.active {
      display: inline-block; }
  .container-guide .guide-img {
    width: 1180px;
    margin-bottom: 40px; }
